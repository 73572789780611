import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const keyBoard = {
  title: 'Keyboard',
  [componentKeys.name]: 'keyboardBase',

  [componentKeys.styles]: {
    ...defaultPosition,
  },
  [componentKeys.properties]: {
    [componentKeys.capsLock]: false,
  },
  [componentKeys.minH]: 20, // percent
  [componentKeys.minW]: 70 // percent
};
