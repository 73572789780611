export default {
  token: {},
  infoSearchCategories: [],
  infoSearchCategory: {},
  selectedPage: {},
  isWorkDay: true,
  patients: [],
  area: {},
  areas: [],
  defaultEmployees: [],
  visitor: {},
  employee: {},
  infoCatStatisticCollection: [],
  companyName: '',
  dymoPrinter: null
};
