export default {
  token: { language: 'no' }, // contains authentication token and it's content: the current user, company, language
  languages: [],
  selectedLang: {},
  currentLangKeys: {},
  loadedPages: new Set(),
  wizard: [],
  building: [],
  tenants: [],
  companyList: [],
  companies: [],
  company: {}, // contains current company, including it's name
  users: [],
  user: {},
  departments: [],
  department: {},
  categories: [],
  category: {},
  jobTitles: [],
  jobTitle: {},
  roles: [],
  role: {},
  notifications: [],
  loading: false,
  employees: [],
  allEmployees: [],
  site: {},
  activeRequests: 0,
  sites: [],
  blockContent: false,
  DropinIndex: 0,
  showVersion: false,
  versionIsColapsed: true,
  usersActivity: [],
  showCsvUploadDialog: false,
  showCsvDownloadDialog: false,
  infoSearchPatients: [],
  infoSearchPatientsSorted: [],
  infoSearchPatientsSortOptions: {
    fieldName: 'LastName',
    order: 0, // possible values are 0,1,2 -> none,asc,desc
  },
  pages: [],
  templates: [],
  template: {},
  showImageUploadPopup: false,
  editorFiles: [],
  showSelectEvacuationMessageDialog: false,
  showEvacuationSmsConfirmationDialog: false,
  showBuildingEvacuationConfirmationDialog: false,
  showBuildingEvacuationDialog: false,
  showSuccessPopup: false,
  siteEvacuationMessages: [],
  activeVisitorsIdsToBeEvacuated: [],
  selectedEvacuationMessageText: '',
  showCsvUploadErrorsPopup: false,
  csvUploadEmployeesError: {
    message: '',
    userEmails: [],
  },
  accessRoutes: [],
  buildingVisitors: [],
  showCompanySelectionDialog: false,
  showUndoDialog: false,
  history: [],
  navigationObject: {},
  showMobileMenu: false,
  loginPicture: ''
};
