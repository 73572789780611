import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";
import textAlignProperties from "@/helpers/wc/textAlignProperties";
import textTags from "@/helpers/wc/textTags";

export const text = {
  title: 'Text',
  isNamedContent: false,
  [componentKeys.name]: 'textBase',
  [componentKeys.text]: [],

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.fontSize]: '12px',
    [componentKeys.fontWeight]: 400,
    [componentKeys.color]: "#000",
    [componentKeys.textAlign]: textAlignProperties.center.value,
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.lineHeight]: null,
  },
  [componentKeys.properties]: {
    [componentKeys.tag]: textTags.none.value,
    [componentKeys.isComputed]: false,
  }
};
