export default {
  left: {
    title: 'Left',
    value: 'left'
  },
  right: {
    title: 'Right',
    value: 'right'
  },
  center: {
    title: 'Center',
    value: 'center'
  },
};
