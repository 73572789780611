// local IIS api
// export const BASE_URL = 'https://localhost:44389/api';

// stage api by default
//eslint-disable-line

const clientService = "http://localhost:8088/token";
let url = "https://api.stage.procon.cloud";
let urlEntur = "https://jexyqg3voh.execute-api.eu-central-1.amazonaws.com/Stage";
let urlMeetings = "https://jt21cmg0aa.execute-api.eu-central-1.amazonaws.com/Stage";
let urlConfigurator = "https://8933kcy1s6.execute-api.eu-central-1.amazonaws.com/Stage";
let urlChurch = "https://kx3u8wale6.execute-api.eu-central-1.amazonaws.com/Stage";
let urlLogger = "https://tlcp9v0z2j.execute-api.eu-central-1.amazonaws.com/Stage";
let urlPreRegisterMeeting = "https://rkntsnqyj6.execute-api.eu-central-1.amazonaws.com/Stage";
let urlVisitors = "https://huxson03u0.execute-api.eu-central-1.amazonaws.com/Stage";
let urlLangService = "https://88yutxkwki.execute-api.eu-central-1.amazonaws.com/Stage";
let urlUsers = "https://jnlf6rtjq5.execute-api.eu-central-1.amazonaws.com/Stage";
let urlKiosks = "https://lridp0eq2k.execute-api.eu-central-1.amazonaws.com/Stage";
let urlMultiQueue = "https://1vbfxr1k3b.execute-api.eu-central-1.amazonaws.com/Stage";
let urlRealTime = "https://9m3x0wrphf.execute-api.eu-central-1.amazonaws.com/Stage";
let urlTalkToKiosk = "https://2gwbw604dd.execute-api.eu-central-1.amazonaws.com/Stage";
const urlSocket = "wss://6b3ljfmxu5.execute-api.eu-central-1.amazonaws.com/Prod";

// ui stage url
let host = 'https://stage.procon.cloud';
let socket = 'wss://';
let s3editor = 'https://s3.eu-central-1.amazonaws.com/webconstructor.procon.stage/editor_files';

// avantor stage :buildingId
let avantorBuilding = 'R03ap2LT';
// eslint-disable-next-line no-unused-vars
let avantorCompany = '4zbewNUO';
// eslint-disable-next-line no-unused-vars
let avantorSite = 'fXBFoavH';

// :siteId
let AvantorSiteID = 'vauAx4yV';
let BrickSiteID = 'fdfY1Ty4';
let CitrixSiteID = '4JEmK4kW';
let ArvalSiteID = 'jWOOjnrB';
let NyarkSiteID = 's8xhzmcT';
let ReguskSiteID = 'ooUXO4ES';
let SatseliSiteID = 'HHOswh1P';
let TheACSiteID = '3GaWUy5J';
let NovoSiteID = 'MtzfGWE3';
let MadarkSiteID = 'WOH2wqER';
let FortiusSiteID = 'GU8xlxjp';
let IteraSiteID = 's5RA5bUa';
let StatnetSiteID = '2JEfW7oA';
let ProffcomSiteID = 'S1aaxLxQ';
let TelehusetSiteID = 'oIR5tSOq';
let SquareheadSiteID = '6RrpPrfX';
let StanleySiteID = 'kkWKfe7V';

// :companyId
let Telehuset = "4zbewNUO";
let Squarehead = "4zbewNUO";
let Itera = "4zbewNUO";
let ArvalS = "4zbewNUO";
let StanleySecurity = "4zbewNUO";
let Statnett = "4zbewNUO";
let SatsElixia = "4zbewNUO";
let Citrix = "4zbewNUO";
let Avantor = "4zbewNUO";
let NydalenArkitektkontor = "4zbewNUO";
let BrickAS = "4zbewNUO";
let NovoNordiskScandinaviaAS = "4zbewNUO";
let MADAS = "4zbewNUO";
let TheAssessmentCompanyAS = "4zbewNUO";
let Proffcom = "4zbewNUO";
let Regus = "4zbewNUO";
let FortiusProsjekt = "4zbewNUO";

// ISS Group Sites stage
// :buildingId
let issBuilding = "xT3nOV4H";

// :siteId
let NAVSiteID = "wh1Mol9e";
let StatensSiteID = "paDAysEe";
let LanekassenSiteID = "szpF1wXp";

// :companyId
let NAV = "ITfTopG0";
let Statens = "YzN9lIsL";
let Lanekassen = "z596dWYv";

// prod api
if (process.env.MODE === 'prod') {
  url = 'https://api.prod.procon.cloud';
  urlEntur = "https://jexyqg3voh.execute-api.eu-central-1.amazonaws.com/Prod";
  urlMeetings = "https://jt21cmg0aa.execute-api.eu-central-1.amazonaws.com/Prod";
  urlConfigurator = "https://8933kcy1s6.execute-api.eu-central-1.amazonaws.com/Prod";
  urlChurch = "https://kx3u8wale6.execute-api.eu-central-1.amazonaws.com/Prod";
  urlLogger = "https://tlcp9v0z2j.execute-api.eu-central-1.amazonaws.com/Prod";
  urlPreRegisterMeeting = "https://event.procon.cloud";
  urlVisitors = "https://visitor.procon.cloud";
  urlLangService = "https://88yutxkwki.execute-api.eu-central-1.amazonaws.com/Prod";
  urlUsers = "https://jnlf6rtjq5.execute-api.eu-central-1.amazonaws.com/Prod";
  urlKiosks = "https://lridp0eq2k.execute-api.eu-central-1.amazonaws.com/Prod";
  urlMultiQueue = "https://1vbfxr1k3b.execute-api.eu-central-1.amazonaws.com/Prod";
  urlRealTime = "https://9m3x0wrphf.execute-api.eu-central-1.amazonaws.com/Prod";
  urlTalkToKiosk = "https://2gwbw604dd.execute-api.eu-central-1.amazonaws.com/Prod";

  socket = 'wss://';

  // ui prod url
  host = 'https://procon.cloud';
  s3editor = 'https://s3.eu-central-1.amazonaws.com/webconstructor.procon.prod/editor_files';

  // avantor prod :buildingId
  avantorBuilding = 'sSVVd3Kv';
  avantorCompany = 'I2jegn9Y';
  avantorSite = 'ccLsXVtp'; // need change

  // :companyId
  Telehuset = "2Ok8WkCx";
  Squarehead = "7YHI4kFF";
  Itera = "A0nlKsYz";
  ArvalS = "aWamN28d";
  StanleySecurity = "axB4bGS0";
  Statnett = "bvKslP2o";
  SatsElixia = "D5CHt8lS";
  Citrix = "Flls5Nk8";
  Avantor = "I2jegn9Y";
  NydalenArkitektkontor = "LcxN4pSV";
  BrickAS = "NrfEYGKv";
  NovoNordiskScandinaviaAS = "rWI6cQ3x";
  MADAS = "TSjWXgFc";
  TheAssessmentCompanyAS = "TwbE3ntk";
  Proffcom = "w3e8tBUo";
  Regus = "vTHnEylR";
  FortiusProsjekt = "Y97Tu15m";

  // :siteId
  AvantorSiteID = 'uLNKQUV2';
  BrickSiteID = 'q7wzMJUm';
  CitrixSiteID = 'XI0geoq6';
  ArvalSiteID = 'Tu2n13LB';
  NyarkSiteID = '9uMTDo4b';
  ReguskSiteID = 'drRQEmUA';
  SatseliSiteID = '9RsC9V26';
  TheACSiteID = 'zBAsAJvU';
  NovoSiteID = 'lPJx1WEL';
  MadarkSiteID = 'fs2VRxr9';
  FortiusSiteID = 'Q16CzcT9';
  IteraSiteID = 'sErRUSXr';
  StatnetSiteID = '4P4gHKxL';
  ProffcomSiteID = 'C7cymjIC';
  TelehusetSiteID = 'mGCOFbvD';
  SquareheadSiteID = '4ekPFp6X';
  StanleySiteID = 'HDy8k6qz';

  // ISS Group Sites prod
  // :buildingId
  issBuilding = "xT3nOV4H";

  // :siteId
  NAVSiteID = "wh1Mol9e";
  StatensSiteID = "paDAysEe";
  LanekassenSiteID = "szpF1wXp";

  // :companyId
  NAV = "ITfTopG0";
  Statens = "YzN9lIsL";
  Lanekassen = "z596dWYv";
}

// local docker compose api
if (process.env.MODE === 'dev') {
  url = 'https://localhost:44389';
  urlEntur = "https://localhost:44367";
  urlMeetings = "https://localhost:44340";
  urlConfigurator = "https://localhost:44374";
  urlChurch = "https://localhost:44317";
  urlLogger = "https://localhost:44304";
  urlPreRegisterMeeting = "https://localhost:44333";
  urlVisitors = "https://localhost:44386";
  urlLangService = "https://localhost:44307";
  urlUsers = "https://localhost:44332";
  urlKiosks = "https://localhost:44380";
  urlMultiQueue = "https://localhost:44382";
  urlRealTime = "https://localhost:44384";
  urlTalkToKiosk = "https://localhost:44360";
  // ui prod url
  host = 'https://localhost:8000';

  socket = 'wss://';

  // avantor prod
  avantorBuilding = 'sSVVd3Kv';
  avantorCompany = 'I2jegn9Y';
  avantorSite = 'ccLsXVtp'; // need change

  Telehuset = "2Ok8WkCx";
  Squarehead = "7YHI4kFF";
  Itera = "A0nlKsYz";
  ArvalS = "aWamN28d";
  StanleySecurity = "axB4bGS0";
  Statnett = "bvKslP2o";
  SatsElixia = "D5CHt8lS";
  Citrix = "Flls5Nk8";
  Avantor = "I2jegn9Y";
  NydalenArkitektkontor = "LcxN4pSV";
  BrickAS = "NrfEYGKv";
  NovoNordiskScandinaviaAS = "rWI6cQ3x";
  MADAS = "TSjWXgFc";
  TheAssessmentCompanyAS = "TwbE3ntk";
  Proffcom = "w3e8tBUo";
  Regus = "vTHnEylR";
  FortiusProsjekt = "Y97Tu15m";

  AvantorSiteID = 'uLNKQUV2';
  BrickSiteID = 'q7wzMJUm';
  CitrixSiteID = 'XI0geoq6';
  ArvalSiteID = 'Tu2n13LB';
  NyarkSiteID = '9uMTDo4b';
  ReguskSiteID = 'drRQEmUA';
  SatseliSiteID = '9RsC9V26';
  TheACSiteID = 'zBAsAJvU';
  NovoSiteID = 'lPJx1WEL';
  MadarkSiteID = 'fs2VRxr9';
  FortiusSiteID = 'Q16CzcT9';
  IteraSiteID = 'sErRUSXr';
  StatnetSiteID = '4P4gHKxL';
  ProffcomSiteID = 'C7cymjIC';
  TelehusetSiteID = 'mGCOFbvD';
  SquareheadSiteID = '4ekPFp6X';
  StanleySiteID = 'HDy8k6qz';
}
export const ClientService = clientService;
export const BASE_URL = url + '/api';
export const UrlEntur = urlEntur;
export const UrlMeetings = urlMeetings;
export const UrlConfigurator = urlConfigurator;
export const UrlChurch = urlChurch;
export const UrlLogger = urlLogger;
export const UrlPreRegisterMeeting = urlPreRegisterMeeting;
export const UrlVisitors = urlVisitors;
export const UrlLangService = urlLangService;
export const UrlUsers = urlUsers;
export const UrlKiosks = urlKiosks;
export const UrlMultiQueue = urlMultiQueue;
export const UrlRealTime = urlRealTime;
export const UrlTalkToKiosk = urlTalkToKiosk;
export const UrlSocket = urlSocket;
export const ApiUrls = {
  WebConstructor: url,
  Entur: urlEntur,
  MeetingRoom: urlMeetings,
  Configurator: urlConfigurator,
  Church: urlChurch,
  Logger: urlLogger,
  ProconEvent: urlPreRegisterMeeting,
  Visitors: urlVisitors,
  Lang: urlLangService,
  Users: urlUsers,
  Kiosks: urlKiosks,
  MultiQueue: urlMultiQueue,
  RealTime: urlRealTime,
  TalkToKiosk: urlTalkToKiosk,
  Socket: urlSocket,
  Queue: process.env.MODE === 'prod' ? 'https://g5js2rnqn2.execute-api.eu-central-1.amazonaws.com/Prod'
    : process.env.MODE === 'dev' ? 'https://localhost:44308/' : 'https://g5js2rnqn2.execute-api.eu-central-1.amazonaws.com/Stage',
  InfoSearch: `${url}/api/InfoSearch`
};

// ui url
export const UIHost = host;
export const SOCKET = socket;
export const S3editor = s3editor;

// Groped sites that have one building with multiple companies

// avantor prod
export const AvantorGroupHome = `${UIHost}/#/R03ap2LT/4zbewNUO/fXBFoavH/visitors/AvantorGroup`;

export const AvantorSite = `${UIHost}/#/${avantorBuilding}/${Avantor}/${AvantorSiteID}/visitors/AvantorSite`;
export const BrickSite = `${UIHost}/#/${avantorBuilding}/${BrickAS}/${BrickSiteID}/visitors/BrickSite`;
export const CitrixSite = `${UIHost}/#/${avantorBuilding}/${Citrix}/${CitrixSiteID}/visitors/CitrixSite`;
export const ArvalSite = `${UIHost}/#/${avantorBuilding}/${ArvalS}/${ArvalSiteID}/visitors/ArvalSite`;
export const NyarkSite = `${UIHost}/#/${avantorBuilding}/${NydalenArkitektkontor}/${NyarkSiteID}/visitors/NyarkSite`;
export const ReguskSite = `${UIHost}/#/${avantorBuilding}/${Regus}/${ReguskSiteID}/visitors/ReguskSite`;
export const SatseliSite = `${UIHost}/#/${avantorBuilding}/${SatsElixia}/${SatseliSiteID}/visitors/SatseliSite`;
export const TheACSite = `${UIHost}/#/${avantorBuilding}/${TheAssessmentCompanyAS}/${TheACSiteID}/visitors/TheACSite`;
export const NovoSite = `${UIHost}/#/${avantorBuilding}/${NovoNordiskScandinaviaAS}/${NovoSiteID}/visitors/NovoSite`;
export const MadarkSite = `${UIHost}/#/${avantorBuilding}/${MADAS}/${MadarkSiteID}/visitors/MadarkSite`;
export const FortiusSite = `${UIHost}/#/${avantorBuilding}/${FortiusProsjekt}/${FortiusSiteID}/visitors/FortiusSite`;
export const IteraSite = `${UIHost}/#/${avantorBuilding}/${Itera}/${IteraSiteID}/visitors/IteraSite`;
export const StatnetSite = `${UIHost}/#/${avantorBuilding}/${Statnett}/${StatnetSiteID}/visitors/StatnetSite`;
export const ProffcomSite = `${UIHost}/#/${avantorBuilding}/${Proffcom}/${ProffcomSiteID}/visitors/ProffcomSite`;
export const TelehusetSite = `${UIHost}/#/${avantorBuilding}/${Telehuset}/${TelehusetSiteID}/visitors/TelehusetSite`;
export const SquareheadSite = `${UIHost}/#/${avantorBuilding}/${Squarehead}/${SquareheadSiteID}/visitors/SquareheadSite`;
export const StanleySite = `${UIHost}/#/${avantorBuilding}/${StanleySecurity}/${StanleySiteID}/visitors/StanleySite`;

// ISS Group Sites (production)
export const ISSHome = `${UIHost}/#/xT3nOV4H/z596dWYv/pFAbl0A8/visitors/ISS`;

export const NAVSite = `${UIHost}/#/${issBuilding}/${NAV}/${NAVSiteID}/visitors/NAV`;
export const StatensSite = `${UIHost}/#/${issBuilding}/${Statens}/${StatensSiteID}/visitors/Statens`;
export const LanekassenSite = `${UIHost}/#/${issBuilding}/${Lanekassen}/${LanekassenSiteID}/visitors/Lanekassen`;

export const DATE_FORMAT_HH_MM = "YYYY-MM-DDTHH:mm:ss.SSS";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_HH = "HH:mm:ss.SSS";
export const HOUR_FORMAT_POPUP = "HH:mm";
export const DATE_FORMAT_SIMPLE_HH_MM = "YYYY-MM-DD HH:mm";
export const DATE_FORMAT_SIMPLE_HH_MM_SS = "DD-MM-YYYY HH:mm:ss";
export const DATE_FORMAT_ONLY_HH_MM = "HH:mm";
export const DATE_FORMAT_CALENDAR_DATE = "DD. MMMM YYYY";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_POPUP = "YYYY/MM/DD";
export const DATE_FORMAT_PRINT_LABEL = "DD.MM.YYYY";
export const DATE_FORMAT_PRINT_DDDD_DD_MMMM_YYY = "dddd DD. MMMM YYYY";
export const INTERVAL_RENDER_MEETING_ROOM = 120000;
export const VERSION_NUMBER = '2.05';
export const socketTypes = {
  ping: 'ping',
  all: 'all',
  reload: 'reload',
  reloadSuccess: 'Reload Success',
};
export const notificationTypes = {
  employee: 0,
  dropin: 1,
  visitors: 2,
  evacuation: 3,
};
export const shiftTypes = {
  lower: 0,
  upper: 1,
  caps: 2
};

export const keyBoardFuncTypes = {
  button: 'button',
  shift: 'shift',
  number: 'number',
  space: 'space',
  enter: 'enter',
  del: "del"
};
export const SiteModules = {
  webConstructor: 0,
  proconEvent: 1,
  visitExpress: 2,
  meetingroom: 3,
  infoSearch: 4,
  queue: 5,
  linked: 6
};
export const logicalOperators = {
  division: 'division',
  multiplication: 'multiplication'
};
export const CompanyType = {
  unknown: 0,
  company: 1,
  parentCompany: 2,
  saveDaily: 4,
  jobDepartment: 8,
  dropinDepartment: 16
};
export const SettingType = {
  unknown: 0,
  azureAd: 1,
  visitorPersistance: 2,
  visitorsCustom: 3,
  companyAgreement: 4,
  smsService: 5,
  meetingDescription: 6,
  azureAdRef: 7,
  emailService: 8,
  customLinks: 9,
  logo: 10,
  kioskAdmin: 11,
  easyPark: 12,
  telenor: 13,
  employeeSync: 14,
  multiQueue: 15,
  emailOptions: 16,
};
export const LogSeverity = {
  requestDetails: 1,
  frontEnd: 2,
  critical: 32,
  moduleError: 64,
  subModuleError: 128,
  warning: 256,
  debug: 512,
  toBeChecked: 1024,
  settingsError: 2048,
};
export const VisitorTypes = {
  none: 0,
  dropin: 1,
  visitExpress: 2,
  appointment: 4,
  removedWithAdminUi: 8,
  removedByVisitor: 16,
  removedByEmployee: 32,
  removedFromCustom: 64,
  custom: 128,
  proconEvent: 256,
  employee: 512,
  queue: 1024,
};

export const _SheetJSFT = [
  "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(x => "." + x).join(",");

export const AceeptExcelCsv = ["csv", "xlsx"].map(x => "." + x).join(",");

export const EMAIL_TEMPLATE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
export const INCREASE_IFRAME_RULES = /<a([^>]*[^/])>iframe|&amp;w\d+|&amp;wauto|&amp;h\d+|&amp;hauto<\/a>/g; //eslint-disable-line
export const FEEDBACK_FORM_RULE = /^.*feedback-(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g; //eslint-disable-line

export const countryCodes = [
  {
    code: '+47',
    country: 'no-en'
  },
  // ! hide in prod
  // * can be uncommitted to tests
  // {
  //   code: '+380',
  //   country: 'ua'
  // },
  {
    code: '+46',
    country: 'sv'
  }
];
