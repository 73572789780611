import constants from '@/store/wc/constants/wc';
import { componentKeys } from "@/helpers/siteSetings";
import _ from "lodash";
import componentsRegister from "@/helpers/wc/componentsRegister";

export default {
  [constants.setSiteResolution](state, payload) {
    state.siteResolution = payload;
  },
  [constants.setPage](state, payload) {
    state.selectedPage = Object.keys(payload).length ? {
      ...payload,
      [componentKeys.components]: payload?.[componentKeys.components]?.map(c => {
        const defaultComponent = componentsRegister.find(el => el.name === c.name);
        c = { ...defaultComponent, ...c };

        if (defaultComponent) {
          // added missed properties into loaded page components
          c[componentKeys.styles] = { ...defaultComponent[componentKeys.styles], ...c[componentKeys.styles] };

          if (!c[componentKeys.styles]) {
            c[componentKeys.styles] = { ...defaultComponent[componentKeys.styles] };
          } else {
            for (const key in c[componentKeys.styles]) {
              // check if default object has component key
              if (!Object.prototype.hasOwnProperty.call(defaultComponent[componentKeys.styles], key)) {
                // remove unused keys
                delete c[componentKeys.styles][key];
              }
            }
          }
        }
        return {
          ...c,
        };
      })
    } : {};
  },
  [constants.setCoefficient](state, payload) {
    state.coefficient = payload;
  },
  [constants.setEmployeeSearchString](state, payload) {
    state.employeeSearchPhrase = payload;
  },
  [constants.setEmployees](state, payload) {
    state.employeeList = payload;
  },
  [constants.setAllEmployees](state, payload) {
    state.allEmployees = payload;
  },
  [constants.setEmployee](state, payload) {
    state.employeeObj = payload;
  },
  [constants.setFinishRegisterSettings](state, payload) {
    state.finishRegisterSettings = payload;
  },
  [constants.setDefaultEmployees](state, payload) {
    state.defaultEmployeeList = payload;
  },
  [constants.setVisitor](state, payload) {
    state.visitorObj = payload;
  },
  [constants.removeVisitor](state, payload) {
    if (state.visitorObj.attendants?.length > 0) {
      state.visitorObj.attendants = state.visitorObj.attendants.filter(v => v.name !== payload);
    } else if (state.visitorObj.name === payload) {
      state.visitorObj = {};
    }
  },
  [constants.setLocalPageSize](state, payload) {
    state.localPageSize = payload;
  },
  [constants.saveDropinSettings](state, payload) {
    state.dropinObject = payload;
  },
  [constants.validateInput](state, payload) {
    state.validate = { ...state.validate, ...payload };
  },
  [constants.deactivatePageComponents](state, { page, components }) {
    state.selectedPage = {
      ...page,
      [componentKeys.components]: _.cloneDeep(components)
        .map(el => ({
          ...el,
          active: false
        }))
    };
  },
  [constants.activateComponent](state, { page, id }) {
    state.selectedPage = {
      ...page,
      [componentKeys.components]: page?.[componentKeys.components]
        .map((el, index) => {
          el = {
            ...el,
            active: false
          };
          if (el.id === id) {
            el = {
              ...el,
              active: true
            };
          }
          return { ...el };
        })
    };
  },
  [constants.updatePageComponents](state, { page, components }) {
    state.selectedPage = {
      ...page,
      [componentKeys.components]: _.cloneDeep(components)
    };
  },
  [constants.setCompanyQueueLines](state, payload) {
    state.companyQueueLines = payload;
  },
  [constants.setQueueVisitors](state, payload) {
    state.queueVisitors = payload;
  },
  [constants.setJobDepartments](state, payload) {
    state.jobDepartments = payload;
  },
  [constants.setJobDepartment](state, payload) {
    state.jobDepartment = payload;
  },
  [constants.setDepartmentUsers](state, payload) {
    state.departmentUsers = payload;
  },
  [constants.setElementsShifted](state, payload) {
    state.elementsShifted = payload;
  },
  [constants.setInfoSearchCategories](state, payload) {
    state.infoSearchCategories = payload;
  },
  [constants.setInfoSearchCategory](state, payload) {
    state.infoSearchCategory = payload;
  },
  [constants.setInfoSearchSubCategory](state, payload) {
    state.infoSearchSubCategory = payload;
  },
  [constants.graveyard](state, payload) {
    state.graveyard = payload;
  },
  [constants.patient](state, payload) {
    state.patient = payload;
  },
  [constants.searchPage](state, payload) {
    state.searchPage = payload;
  },
  [constants.buttonState](state, payload) {
    state.buttonState = payload;
  },
  [constants.shouldAcceptAgreement](state, value) {
    state.agreementSettings.shouldAcceptAgreement = value;
  },
  [constants.acceptAgreement](state, value) {
    state.agreementSettings.acceptedAgreement = value;
    state.agreementSettings.showAcceptAgreementPopup = !value;
  },
  [constants.showAcceptAgreementPopup](state, value) {
    state.agreementSettings.showAcceptAgreementPopup = value;
  },
  [constants.saveAfterAgreementHook](state, value) {
    state.agreementSettings.acceptedHook = value;
  },
  actionResult(state, value) {
    state.actionResult = value;
  },
  'setShouldResetUserAppliedState'(state, value) {
    state.shouldResetUserAppliedState = value;
  }
};
