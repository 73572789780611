import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const infoSearchCategoryContentSettings = {
  title: "InfoSearch Content",
  [componentKeys.name]: "infoSearchCategoryContentBase",

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.color]: "#000000",
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.textAlign]: "left",
    [componentKeys.fontSize]: "16px",
    [componentKeys.paddingTop]: "15",
    [componentKeys.paddingRight]: "15",
    [componentKeys.paddingBottom]: "15",
    [componentKeys.paddingLeft]: "15",
    [componentKeys.bgColor]: 'grey',
    [componentKeys.borderWidth]: '1px',
    [componentKeys.borderStyle]: 'solid',
    [componentKeys.borderColor]: "#000",
    [componentKeys.borderRadius]: '5px',
  },
  [componentKeys.properties]: {
    mode: 0,
    [componentKeys.routeName]: "",
    [componentKeys.infoSearchCategory]: {}
  }
};
