import lfp from "lodash/fp";
import lang from "@/helpers/lang";
import getType from "@/mixins/language/getLangType";

export default {
  mixins: [getType],
  data() {
    return {
      lang
    };
  },
  computed: {
    lg() {
      return this.getType();
    }
  },
  methods: {
    getErrorResponse(data) {
      if (data && !Object.prototype.hasOwnProperty.call(data, 'body')) {
        return this.lang[this.lg].toast.err;
      }
      if (data && !Object.prototype.hasOwnProperty.call(data, 'body') && !Object.prototype.hasOwnProperty.call(data.body, 'message')) {
        return this.lang[this.lg].toast.errEmpty;
      }
      return data?.body?.message;
    },
    getErrorResponseWithMessage(data) {
      if (data && Object.prototype.hasOwnProperty.call(data, 'body') && Object.prototype.hasOwnProperty.call(data.body, 'message')) {
        return data.body?.message ?? "";
      }
      return this.lang[this.lg].toast.err;
    },
    checkBodyForEmptiness: lfp.curry((route, response) =>
      response && response.body && response.body.message
        ? response.body
        : Object.create({ success: false, message: `${route} returned empty body.` })),
    applyActionForResponse: lfp.curry(function(action, errorAction, self, response) {
      return response.success ? action() : errorAction.call(self, response.message).bind(self);
    })
  }
};
