export default {
  setInfoSearchPatients: 'setInfoSearchPatients',
  setInfoSearchPatientsSorted: 'setInfoSearchPatientsSorted',
  setInfoSearchPatientsSortOptions: 'setInfoSearchPatientsSortOptions',
  setPages: 'setPages',
  selectPage: 'selectPage',
  setSites: 'setSites',
  setSite: 'setSite',
  setTemplates: 'setTemplates',
  setTemplate: 'setTemplate',
  selectTemplate: 'selectTemplate',
  toggleUploadImagePopup: 'toggleUploadImagePopup',
  setEditorFiles: 'setEditorFiles',
  selectEditorFile: 'selectEditorFile',
  openCloseSuccessPopup: 'openCloseSuccessPopup',
  setSiteEvacuationMessages: 'siteEvacuationMessages',
  setActiveVisitorsIds: 'setActiveVisitorsIds',
  setEvacuationMessageText: 'setEvacuationMessageText',
  openCloseCsvUploadEmployeesPopup: 'openCloseCsvUploadEmployeesPopup',
  openCloseCsvDownloadEmployeesPopup: 'openCloseCsvDownloadEmployeesPopup',
  openCloseCsvUploadErrorsPopup: 'openCloseCsvUploadErrorsPopup',
  setCsvUploadErrors: 'setCsvUploadErrors',
  setBuildingVisitors: 'setBuildingVisitors',
  externalEmployees: 'externalEmployees',
  setAccessRoutes: 'setAccessRoutes',
  companySelectIonDialog: 'companySelectIonDialog',
  setNavigationObject: 'setNavigationObject',
  navigationObject: 'navigationObject',
};
