import defaultPosition from "@/helpers/wc/positionSettings";

export const stopPointTimingSettings = {
  title: "Entur API",
  name: "stopPointTimingBase",

  styles: defaultPosition,

  properties: {
    stopPlaceId: "",
    transportLines: [],
    nextDeparture: false,
    css: {
      stopPoint: {
        fontFamily: "Arial",
        fontSize: '30px',
        color: 'white',
        backgroundColor: "rgb(23, 23, 23)"
      },
      caption: {
        fontSize: '46px',
        padding: '30px',
        paddingLeft: '46px',
        color: 'white'
      },
      header: {
        fontSize: 14,
        color: '#aeb7e2',
        borderColor: 'rgb(57, 61, 121)',
        borderWidth: '2px'
      },
      row: {
        height: '46px',
        borderColor: 'rgb(57, 61, 121)',
        borderWidth: '2px'
      },
      time: {
        fontSize: '30px',
      }
    }
  },
  cssLegend: {
    stopPoint: {
      name: "Root",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      },
    },
    caption: {
      name: "Caption",
      fontSize: {
        type: "int",
        name: "Height",
        handler: val => val + 'px'
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      },
      paddingLeft: {
        type: "int",
        name: "Left Padding",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
    },
    header: {
      name: "Lines Header",
      color: {
        type: "color",
        name: "Color"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      borderColor: {
        type: "color",
        name: "Bottom Border"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      }
    },
    row: {
      name: "Line Item",
      height: {
        type: "int",
        name: "Height",
        handler: val => val + 'px'
      },
      borderColor: {
        type: "color",
        name: "Bottom Border"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      }
    },
    time: {
      name: "Departure Time",
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      }
    }
  }
};
