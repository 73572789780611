import sites from "@/mixins/admin/requests/sites/sites";
import sitePages from "@/mixins/admin/requests/sitePages/sitePages";

import templates from "@/mixins/admin/requests/templates/templates";
import templatePages from "@/mixins/admin/requests/templatePages/templatePages";

import editorFiles from "@/mixins/admin/requests/editorFiles/editorFiles";

import queue from "@/mixins/admin/requests/queue/getQueueLines";

import visitors from "@/mixins/admin/requests/visitors/visitors";

import getEvacuationMessagesForSite
  from "@/mixins/admin/requests/messages/evacuationMessages/getEvacuationMessagesForSite";
import sendEvacuationMessageToActiveVisitors
  from "@/mixins/admin/requests/messages/evacuationMessages/sendEvacuationMessageToActiveVisitors";

export default {
  mixins: [
    sites,
    sitePages,
    templates,
    templatePages,
    editorFiles,
    queue,
    visitors,
    getEvacuationMessagesForSite,
    sendEvacuationMessageToActiveVisitors
  ]
};
