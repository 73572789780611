export default {
  data() {
    return {
      socket: null,
      closed: false,
      socketUrl: '',
    };
  },
  methods: {
    checkAliveSocket(url, reconnect) {
      if (!this.isOpen(this.socket)) {
        if (this.socket.readyState === this.socket.CLOSED) {
          if (navigator.onLine) {
            if (reconnect) {
              reconnect();
            }
            this.connect(url);
          }
        } else {
          if (!this.closed) {
            this.closeSocket();
          }
        }
      }
    },
    onSocketError(event) {
      console.log("error: " + event);
    },
    onCloseSocket(event) {
      this.closed = true;
      console.log("closed connection from " + this.uri);
    },
    closeSocket() {
      if (this.socket) {
        this.socket.close(1000, "Closing from client");
      }
      this.closed = true;
    },
    connect(url = "") {
      if (!this.isOpen(this.socket)) {
        this.connectSocket(url);
      }
    },
    isOpen(ws) {
      if (ws) {
        return ws.readyState === ws.OPEN;
      }
      return false;
    },
    connectSocket(url = "") {
      const uri = url !== "" ? url : this.uri;
      this.socketUrl = uri;
      this.socket = new WebSocket(uri);
      this.socket.onopen = (event) => {
        if (this.onOpenSocket) {
          this.onOpenSocket(event);
        }
      };
      this.socket.onclose = (event) => {
        if (this.onCloseSocket) {
          this.onCloseSocket(event);
        }
      };
      this.socket.onmessage = (event) => {
        if (this.onSocketMessage) {
          this.onSocketMessage(event);
        }
      };
      this.socket.onerror = (event) => {
        if (this.onSocketError) {
          this.onSocketError(event);
        }
      };
    },
    sendMessage(message) {
      if (!this.isOpen(this.socket)) {
        if (this.socket.readyState === this.socket.CLOSED) {
          if (navigator.onLine) {
            this.connect(this.socketUrl);
          }
        } else {
          if (!this.closed) {
            this.closeSocket();
          }
        }
      } else {
        this.socketSend(message);
      }
    },
    socketSend(message) {
      this.socket.send(message);
    }
  }
};
