import componentKeys from "@/helpers/wc/componentKeys";

export const redirectSettings = {
  title: "Redirect",
  [componentKeys.name]: "redirectBase",

  [componentKeys.styles]: {
    [componentKeys.w]: '1px', [componentKeys.h]: '1px', [componentKeys.x]: 0, [componentKeys.y]: 0, [componentKeys.z]: 0,
  },
  [componentKeys.properties]: {
    [componentKeys.routeName]: "",
    [componentKeys.pageWidth]: [0, 0],
    [componentKeys.pageHeight]: [0, 0],
  }
};
