import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const panel = {
  title: 'Panel',
  [componentKeys.name]: 'panelBase',

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.bgColor]: 'grey',
    [componentKeys.borderWidth]: '1px',
    [componentKeys.borderStyle]: 'solid',
    [componentKeys.borderColor]: "#000",
    [componentKeys.borderRadius]: '5px',
  },
  [componentKeys.properties]: {}
};
