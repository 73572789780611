import lang from "@/helpers/lang";

export default {
  data() {
    return {
      lang
    };
  },
  methods: {
    getType() {
      const type = this.$store.state.token?.language || localStorage.lang || 'no';
      if (this.$awn && this.lang[type]?.toast) {
        this.$awn.options.labels = {
          success: this.lang[type].toast.success,
          warning: this.lang[type].toast.warning,
          alert: this.lang[type].toast.alert,
        };
      }
      return type;
    }
  }
};
