export const permissions = {
  KioskSite: [0x1, 0, 0, 0],
  SuperAdmin: [0x2, 0, 0, 0],
  AdminDropInDepartments: [0x4, 0, 0, 0],
  CompanySettings: [0x8, 0, 0, 0],
  Companies: [0x10, 0, 0, 0], // 16
  MultiQueue: [0x20, 0, 0, 0], // 32
  KioskMonitor: [0x40, 0, 0, 0], // 64
  CreateCompany: [0x80, 0, 0, 0], // 128
  DropInNotifications: [0x100, 0, 0, 0], // 256
  DropInDepartments: [0x200, 0, 0, 0], // 512
  UserEditYourself: [0x400, 0, 0, 0], // 1024
  EmployeeNotifications: [0x800, 0, 0, 0], // 2048
  Employees: [0x1000, 0, 0, 0], // 4096
  EvacuationMessages: [0x2000, 0, 0, 0], // 8192
  CompanyLogo: [0x4000, 0, 0, 0], // 16384
  InfoSearch: [0x8000, 0, 0, 0], // 32768 - InfoSearch Categories & Statistics
  InfoSearchSites: [0x10000, 0, 0, 0], // 65536
  InfoSearchAreas: [0x20000, 0, 0, 0], // 131072
  InfoSearchPatients: [0x40000, 0, 0, 0], // 262144
  JobDepartments: [0x80000, 0, 0, 0], // 524288
  JobTitles: [0x100000, 0, 0, 0], // 1048576
  AzureAD: [0x200000, 0, 0, 0], // 2097152
  MeetingRooms: [0x400000, 0, 0, 0], // 4194304
  CustomEmployeeSync: [0x800000, 0, 0, 0], // 8388608
  Notifications: [0x1000000, 0, 0, 0], // 16777216
  VisitExpressSites: [0x2000000, 0, 0, 0], // 33554432
  QueueLines: [0x4000000, 0, 0, 0], // 67108864
  Roles: [0x8000000, 0, 0, 0], // 134217728
  Barcodes: [0x10000000, 0, 0, 0], // 268435456
  SitePages: [0x20000000, 0, 0, 0], // 536870912
  Sites: [0x40000000, 0, 0, 0], // 1073741824
  SiteSettings: [0x80000000, 0, 0, 0], // 2147483648
  // Int32
  Statistics: [0, 0x1, 0, 0], // 4294967296
  TemplatePages: [0, 0x2, 0, 0], // 8589934592
  Templates: [0, 0x4, 0, 0], // 17179869184
  UserActivities: [0, 0x8, 0, 0], // 34359738368
  Users: [0, 0x10, 0, 0], // 68719476736
  VisitorNotifications: [0, 0x20, 0, 0], // 137438953472
  Visitors: [0, 0x40, 0, 0], // 274877906944
  VisitExpress: [0, 0x80, 0, 0], // 549755813888
  Entur: [0, 0x100, 0, 0], // 1099511627776
  EasyPark: [0, 0x200, 0, 0], // 2199023255552
  Taxi: [0, 0x400, 0, 0], // 4398046511104
  ProconEvent: [0, 0x800, 0, 0], // 8796093022208
  VisitorsList: [0, 0x1000, 0, 0], // 17592186044416

  // Second double word
  CompanyAgreement: [0, 0, 0x1, 0],
  SMSsettings: [0, 0, 0x2, 0],
  AdminDropInDepartmentsWrite: [0, 0, 0x4, 0],
  AdminDropInDepartmentsLimitedWrite: [0, 0, 0x8, 0],
  CompaniesWrite: [0, 0, 0x10, 0], // 16
  EmailSettings: [0, 0, 0x20, 0], // 32
  MultiQueueSettings: [0, 0, 0x40, 0], // 64
  ExternalEmployee: [0, 0, 0x80, 0], // 128
  DropInNotificationsWrite: [0, 0, 0x100, 0], // 256
  DropInDepartmentsWrite: [0, 0, 0x200, 0], // 512
  DropInUsersWrite: [0, 0, 0x400, 0], // 1024
  EmployeeNotificationsWrite: [0, 0, 0x800, 0], // 2048
  EmployeesWrite: [0, 0, 0x1000, 0], // 4096
  EvacuationMessagesWrite: [0, 0, 0x2000, 0], // 8192
  EmployeeExtensionsWrite: [0, 0, 0x4000, 0], // 16384 - Employee properties that are not bound to Azure AD
  InfoSearchWrite: [0, 0, 0x8000, 0], // 32768 - InfoSearch Categories & Statistics
  InfoSearchSitesWrite: [0, 0, 0x10000, 0], // 65536
  InfoSearchAreasWrite: [0, 0, 0x20000, 0], // 131072
  InfoSearchPatientsWrite: [0, 0, 0x40000, 0], // 262144
  JobDepartmentsWrite: [0, 0, 0x80000, 0], // 524288
  JobTitlesWrite: [0, 0, 0x100000, 0], // 1048576
  WorkingTime: [0, 0, 0x200000, 0], // 2097152
  MeetingRoomsWrite: [0, 0, 0x400000, 0], // 4194304
  MeetingServicesWrite: [0, 0, 0x800000, 0], // 8388608
  NotificationsWrite: [0, 0, 0x1000000, 0], // 16777216
  TelenorStyle: [0, 0, 0x2000000, 0], // 33554432
  QueueLinesWrite: [0, 0, 0x4000000, 0], // 67108864
  RolesWrite: [0, 0, 0x8000000, 0], // 134217728
  EmployeesWriteExternal: [0, 0, 0x10000000, 0], // 268435456
  SitePagesWrite: [0, 0, 0x20000000, 0], // 536870912
  SitesWrite: [0, 0, 0x40000000, 0], // 2147483648
  Empty17: [0, 0, 0x80000000, 0],
  // Int32
  Empty18: [0, 0, 0, 0x1], // 4294967296
  TemplatePagesWrite: [0, 0, 0, 0x2], // 8589934592
  TemplatesWrite: [0, 0, 0, 0x4], // 17179869184
  Empty19: [0, 0, 0, 0x8], // 34359738368
  UsersWrite: [0, 0, 0, 0x10], // 68719476736
  VisitorNotificationsWrite: [0, 0, 0, 0x20], // 137438953472
  VisitorNotificationsLimitedWrite: [0, 0, 0, 0x40], // 274877906944
  VisitExpressWrite: [0, 0, 0, 0x80], // 549755813888
  EnturWrite: [0, 0, 0, 0x100], // 1099511627776
  EasyParkWrite: [0, 0, 0, 0x200], // 2199023255552
  TaxiWrite: [0, 0, 0, 0x400], // 4398046511104
  ProconEventWrite: [0, 0, 0, 0x800], // 8796093022208
  Empty21: [0, 0, 0, 0x1000], // 17592186044416
};
