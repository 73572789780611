import defaultPosition from "@/helpers/wc/positionSettings";

export const visitorsCustomListSettings = {
  title: 'Custom Visitors List',
  name: 'visitorsCustomListBase',

  styles: defaultPosition,

  properties: {
    css: {
      table: {
        borderColor: 'grey',
        borderWidth: '2px'
      },
      header: {
        fontFamily: "Arial",
        fontSize: '20px',
        color: 'black',
        backgroundColor: 'white',
        textAlign: 'center',
        height: '50px',
        borderBottomColor: 'grey',
        borderBottomWidth: '2px',
        padding: '2px'
      },
      body: {
        fontFamily: "Arial",
        fontSize: '16px',
        color: 'black',
        backgroundColor: 'white'
      },
      row: {
        borderBottomColor: 'grey',
        borderBottomWidth: '1px',
      },
      cell: {
        height: '30px',
        justifyContent: 'center',
        borderTopColor: 'grey',
        borderTopWidth: '1px',
        borderBottomColor: 'grey',
        borderBottomWidth: '1px',
        padding: '2px'
      }
    }
  },
  cssLegend: {
    table: {
      borderColor: {
        type: "color",
        name: "Border Color"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      },
    },
    header: {
      name: "Table Header",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      },
      textAlign: {
        type: "align",
        name: "Align"
      },
      height: {
        type: "int",
        name: "Height",
        handler: val => val + 'px'
      },
      borderBottomColor: {
        type: "color",
        name: "Border Color"
      },
      borderBottomWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      }
    },
    body: {
      name: "Table Body",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      }
    },
    row: {
      name: "Table Row",
      borderBottomColor: {
        type: "color",
        name: "Border Color"
      },
      borderBottomWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      },
    },
    cell: {
      name: "Table Cells",
      justifyContent: {
        type: "align",
        name: "Justify Content"
      },
      height: {
        type: "int",
        name: "Height",
        handler: val => val + 'px'
      },
      borderTopColor: {
        type: "color",
        name: "Border Top Color"
      },
      borderTopWidth: {
        type: "int",
        name: "Border Top Width",
        handler: val => val + 'px'
      },
      borderBottomColor: {
        type: "color",
        name: "Border Bottom Color"
      },
      borderBottomWidth: {
        type: "int",
        name: "Border Bottom Width",
        handler: val => val + 'px'
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      }
    }
  }
};
