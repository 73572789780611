import defaultPosition from "@/helpers/wc/positionSettings";

export const departmentsSettings = {
  title: "Departments",
  name: "departmentsBase",

  styles: defaultPosition,

  properties: {
    departmentType: 0,
    tags: "",
    limit: 0,
    routeName: '',
    css: {
      layout: {
        backgroundColor: "transparent",
        textAlign: "center"
      },
      departmentButton: {
        width: "200px",
        height: "50px",
        marginTop: "20px",
        marginRight: "20px",
        fontFamily: "Arial",
        fontSize: '20px',
        color: 'white',
        backgroundColor: "rgb(50, 100, 250)",
        borderColor: "blue",
        borderWidth: "2px",
        borderRadius: "10px"
      },
    }
  },
  cssLegend: {
    layout: {
      name: "Layout",
      backgroundColor: {
        type: "color",
        name: "Background"
      },
      textAlign: {
        type: "align",
        name: "Align"
      }
    },
    departmentButton: {
      name: "Department Buttons",
      width: {
        type: "int",
        name: "Width",
        handler: val => val + 'px'
      },
      height: {
        type: "int",
        name: "Height",
        handler: val => val + 'px'
      },
      marginTop: {
        type: "int",
        name: "Margin Top",
        handler: val => val + 'px'
      },
      marginRight: {
        type: "int",
        name: "Margin Right",
        handler: val => val + 'px'
      },
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      },
      borderColor: {
        type: "color",
        name: "Bottom Border"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      },
      borderRadius: {
        type: "int",
        name: "Border Radius",
        handler: val => val + 'px'
      },
    }
  }
};
