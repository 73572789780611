import MiddlewareService from "@/MiddlewareService";
import getErrorResponse from "@/mixins/getResponseData";
import constants from "@/store/wc/constants/wc";

export default {
  mixins: [MiddlewareService, getErrorResponse],
  computed: {
    currentSite() {
      return this.$store.state.selectedPage.site;
    }
  },
  methods: {
    /**
     * * Send request to API to get company Queue Lines
     * *  @param {string|optional} companyId. Current company Id
     */
    async getQueueLinesByCompanyId(companyId = '', siteId = '') {
      if (!companyId) {
        companyId = this.currentSite.companyId;
      }
      const route = `/QueueLines/For?companyId=${companyId}&siteId=${siteId}`;
      const data = await this.processRequest(route);
      if (data?.body) {
        await this.$store.dispatch(constants.setCompanyQueueLines, data.body);
      } else {
        this.$awn.alert(this.getErrorResponseWithMessage(data));
      }
    },
  }
};
