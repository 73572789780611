import Vue from "vue";
import VueResource from "vue-resource";
import Router from "vue-router";
import routes from "@/helpers/router/routes";

Vue.use(Router);
Vue.use(VueResource);

const router = new Router({
  routes: routes
});

export default router;
