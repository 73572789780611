import defaultPosition from "@/helpers/wc/positionSettings";

export const talkSettings = {
  title: 'Talk',
  name: 'talkBase',
  styles: defaultPosition,
  files: [],

  properties: {
    instructions: 22,
    greetingMessage: 'Welcome! What can I help you?',
    firewellMessage: '',
    workflow: 0,
    voice: 'alloy',
    speed: 1.0,
    fileWaitingSound: '',
    waitingSoundVolume: 0.5,
    receptionistId: '',
    receptionistMessage: '',
    sendOnSilent: 2,
    stopOnSilent: 10,
    emailEmployee: false,
    smsEmployee: true,
    employeeMessage: '',
    smsVisitor: true,
    isVisitorMessage: false,
    visitorMessage: [],
    css: {
      button: {
        color: 'white',
        backgroundColor: 'rgb(23, 23, 23)',
        borderColor: 'rgb(57, 61, 121)',
        borderWidth: '2px',
        borderRadius: '20px'
      },
      dialog: {
        width: '50px',
        maxHeight: '300px',
        left: 0,
        top: 0,
        fontSize: 14,
        backgroundColor: 'white',
        borderRadius: '20px',
        color: '#aeb7e2',
        borderWidth: '2px',
        padding: '5px'
      }
    }
  },
  cssLegend: {
    button: {
      name: "Root",
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      },
      borderColor: {
        type: "color",
        name: "Border"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      },
      borderRadius: {
        type: "int",
        name: "Border Radius",
        handler: val => val + 'px'
      }
    },
    dialog: {
      name: "Dialog Block",
      width: {
        type: "int",
        name: "Width",
        handler: val => val + 'px'
      },
      maxHeight: {
        type: "int",
        name: "MaxHeight",
        handler: val => val + 'px'
      },
      left: {
        type: "int",
        name: "left",
        handler: val => val + 'px'
      },
      top: {
        type: "int",
        name: "top",
        handler: val => val + 'px'
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      backgroundColor: {
        type: "color",
        name: "Background Color"
      },
      borderRadius: {
        type: "int",
        name: "Border Radius",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Border color"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      }
    }
  }
};
