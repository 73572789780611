import Vue from 'vue';
import Vuex from 'vuex';

// logger
import createLogger from 'vuex/dist/logger';

// state
import adminState from '@/store/admin/state/admin';
import wcState from '@/store/wc/state/wc';
import clientState from '@/store/client/state/client';

// mutations
import adminMutations from '@/store/admin/mutations/admin';
import wcMutations from '@/store/wc/mutations/wc';
import clientMutations from '@/store/client/mutations/client';

// getters
import adminGetters from '@/store/admin/getters/admin';
import wcGetters from '@/store/wc/getters/wc';
import clientGetters from '@/store/client/getters/client';

// actions
import adminActions from '@/store/admin/actions/admin';
import wcActions from '@/store/wc/actions/wc';
import clientActions from '@/store/client/actions/client';

Vue.use(Vuex);

const isProduction = process.env.NODE_ENV === 'production';
const plugins = [];

// added logger only for development purposes
if (!isProduction) {
  plugins.push(createLogger());
}

const state = {
  ...adminState,
  ...wcState,
  ...clientState
};

const mutations = {
  ...adminMutations,
  ...wcMutations,
  ...clientMutations
};

const getters = {
  ...adminGetters,
  ...wcGetters,
  ...clientGetters,
};

const actions = {
  ...adminActions,
  ...wcActions,
  ...clientActions
};

export default new Vuex.Store({
  plugins,
  state,
  mutations,
  getters,
  actions
});
