// register of all basic components settings keys for easier changing
export default {
  // styles properties
  display: "display",
  fontSize: "fontSize",
  fontFamily: "fontFamily",
  fontWeight: "fontWeight",
  color: "color",
  opacity: "opacity",
  borderWidth: "borderWidth",
  borderStyle: "borderStyle",
  borderColor: "borderColor",
  bgColor: "backgroundColor",
  borderRadius: "borderRadius",
  textAlign: "textAlign",
  selectedItemFontColor: 'primaryColor',
  selectedItemBgColor: 'secondaryColor',

  marginTop: "marginTop",
  marginRight: "marginRight",
  marginBottom: "marginBottom",
  marginLeft: "marginLeft",

  paddingTop: "paddingTop",
  paddingRight: "paddingRight",
  paddingBottom: "paddingBottom",
  paddingLeft: "paddingLeft",
  flexDirection: "flexDirection",
  justifyContent: "justifyContent",
  alignItems: "alignItems",
  itemWidth: "itemWidth",
  itemHeight: "itemHeight",
  lineHeight: 'line-height',

  // position properties
  w: "w",
  h: "h",
  x: "x",
  y: "y",
  z: "z",
  minH: "minH",
  minW: "minW",
  shiftedY: "shiftedY",
  ableToShift: "ableToShift",
  transform: "transform",

  tag: "tag",
  isComputed: "isComputed",
  styles: "styles",
  properties: "properties",
  imageId: "imageId",
  videoId: "videoId",
  text: "pageComponentContents",
  contentArray: "pageComponentContents",
  placeholder: "pageComponentContents",
  url: "imageUrl",
  externalUrl: "externalUrl",
  siteUrl: "siteUrl",
  routeName: "routeName",
  type: "type",
  startHour: "startHour",
  endHour: "endHour",
  checkDays: "checkDays",
  days: "days",
  value: "content",
  name: "name",
  position: "position",
  settings: "settings",
  components: "pageComponents",
  inputId: "id",
  inputType: "type",
  capsLock: "capsLock",
  pageWidth: "width",
  pageHeight: "height",
  class: "class",
  pageTimer: "pageTimer",
  returnRouteName: "timeOutBackPageId",
  externalReturnUrl: "externalReturnUrl",
  departmentId: "departmentId",
  outWorkHoursRouteName: "outWorkHoursRouteName",
  departmentSentSms: "departmentSentSms",
  departmentSentEmail: "departmentSentEmail",
  departmentSentToVisitor: "departmentSentToVisitor",
  sendMessageToDropinVisitor: "sendMessageToDropinVisitor",
  employeeSendEmail: "employeeSendEmail",
  visitorSendNotification: "visitorSendNotification",
  employeeSendSMS: "employeeSendSMS",
  registerQueueVisitor: "registerQueueVisitor",
  queueLineName: "queueLineName",
  queueLineId: "queueLineId",
  queueId: "queueId",
  soundtrack: "soundtrack",
  checkBoxValue: "checkBoxValue",
  checkBoxStyleType: "checkBoxStyleType",
  buttonId: "buttonId",
  printLabel: "printLabel",
  messageToVisitor: "messageToVisitor",
  messageToEmployee: "messageToEmployee",
  jobDepartment: "jobDepartment",
  userItemWidth: "userItemWidth",
  userItemImageBorderWidth: "userItemImageBorderWidth",
  userItemImageBorderStyle: "userItemImageBorderStyle",
  userItemImageBorderColor: "userItemImageBorderColor",
  userItemImageBorderRadius: "userItemImageBorderRadius",
  employeesCount: "employeesCount",
  showName: "showName",
  showJobTitle: "showJobTitle",
  showPhone: "showPhone",
  breakLine: "breakLine",
  fetchFromDepartment: "fetchFromDepartment",
  messageTagVisitorCompany: "messageTagVisitorCompany",
  messageTagVisitorName: "messageTagVisitorName",
  messageTagVisitorPhone: "messageTagVisitorPhone",
  messageTagVisitorAttendants: "messageTagVisitorAttendants",
  customMessageEmployee: "customMessageEmployee",
  customEmployeeMessageContent: "customEmployeeMessageContent",
  customDropinFormMessage: "customDropinFormMessage",
  customDropinFormMessageContent: "customDropinFormMessageContent",
  sendNotificationToEmployee: "sendNotificationToEmployee",
  labelTemplate: "labelTemplate",
  sendSms: "sendSms",
  sendEmail: "sendEmail",
  selectDropinByOrder: "selectDropinByOrder",
  selectDropinByName: "selectDropinByName",
  dropinOrder: "dropinOrder",
  dropinName: "dropinName",
  redirectToRegister: "redirectToRegister",
  finishRegister: "finishRegister",
  infoSearchCategory: "infoSearchCategory",
  infoSearchCategories: "infoSearchCategories",
  fakeCategoriesCount: "fakeCategoriesCount",
  openInNewWindow: "openInNewWindow",
  openInNewTab: "openInNewTab",
  saveStatistics: "saveStatistics",
  saveStatisticsAs: "saveStatisticsAs",
  selectPersonConfirmDialog: "selectConfirmDialog",
  selectPersonConfirmDialogBody: "selectConfirmDialogBody",
  selectPersonConfirmDialogConfirmBtn: "selectConfirmDialogConfirmBtn",
  selectPersonConfirmDialogCancelBtn: "selectConfirmDialogCancelBtn",

  // Soundtracks for Queue
  track: "track",

  // validation
  needValidate: "needValidate",
  disableValidationOutline: "disableValidationOutline",
  isRequired: "isRequired",
  maxLength: "maxLength",

  // StopPlace component
  stopPlaceId: "stopPlaceId",
  transportLines: "transportLines",
  headerTextSize: "headerTextSize",

  // Clock
  dateFormat: "dateFormat",

  // Parking
  areaNo: "areaNo",
  parkUserName: 'partUserName',
  neededDefaultParkingHours: 'neededDefaultParkingHours',
  parkingHours: 'parkingHours',

  // Taxi
  city: "city",
  postal: "postal",
  streetName: "streetName",
  streetNumber: "streetNumber",
  streetLetter: "streetLetter",
  central: 'central',
  longitude: "longitude",
  latitude: "latitude",

  // Response Message
  responseMessage: "responseMessage",

  // svv open hours
  station: 'station',
  openHoursRefresh: 'hoursRefresh',
  openHoursText: 'openHoursText',
  svvAddressText: 'svvAddressText',

  // langSwitcher
  oneClickSelect: 'oneClickSelect',
  direction: 'direction',
  expanded: 'expanded',

  //
  printImmediately: 'printImmediately',

  companyId: "companyId",
};
