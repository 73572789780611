import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";
import imageTags from "@/helpers/wc/imageTags";

export const imageTypesRegister = {
  image: {
    title: "Image",
    value: 1
  },
  redirect: {
    title: "Redirect",
    value: 2
  },
  clearInput: {
    title: "Clear Input",
    value: 3
  },
  externalLink: {
    title: "External Link",
    value: 4
  },
};

export const image = {
  title: 'Image',
  [componentKeys.name]: 'imageBase',

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.opacity]: "1",
  },

  [componentKeys.properties]: {
    [componentKeys.type]: imageTypesRegister.image.value,
    [componentKeys.inputId]: "",
    [componentKeys.routeName]: "",
    [componentKeys.url]: null,
    [componentKeys.tag]: imageTags.none.value,
    [componentKeys.isComputed]: false,
    [componentKeys.externalUrl]: null,
  }
};
