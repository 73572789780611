import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";
import constants from "@/store/admin/constants/admin";
import WCConstants from "@/store/wc/constants/wc";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  methods: {
    /**
     * * Send request to API to get all page for template
     *  @param {String | Optional} templateId. Template ID
     */
    async getTemplatePages(templateId = this.$route.params.templateId) {
      const data = await this.processRequest(`/TemplatePages/${templateId}/Pages`);
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setPages, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
    /**
     * * Send request to API to get current page details by Id
     *  @param {String} pageId. Page ID
     *  @return {Object | null} Returns status object if success or null
     */
    async getTemplatePageDetails(pageId) {
      const data = await this.processRequest(`/TemplatePages/${pageId}`);
      if (data?.body?.success) {
        await this.$store.dispatch(WCConstants.setTemplateActivePage, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },

    /**
     * * Send request to API to update properties on all pages in current template
     *  @param {Object} model. Page model with properties
     *  @return {Object | null} Returns status object if success or null
     */
    async updatePropertiesForAllTemplatePages(model) {
      const data = await this.processRequest("/TemplatePages/UpdateProperties", "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },

    /**
     * * Send request to API to remove template page
     *  @param {String} pageId. Page ID
     */
    async removeTemplatePage(pageId) {
      const data = await this.processRequest(`/TemplatePages/${pageId}`, "delete");
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to create new template page
     *  @param {Object} model. Page model with properties
     *  @return {Object | null} Returns status object if success or null
     */
    async createTemplatePage(model) {
      const data = await this.processRequest("/TemplatePages", "post", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to update existing template page
     *  @param {Object} model. Page model with properties
     *  @return {Object | null} Returns status object if success or null
     */
    async updateTemplatePage(model) {
      const data = await this.processRequest("/TemplatePages", "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to update existing template page components
     *  @param {Object} model. Page model with components
     *  @return {Object | null} Returns status object if success or null
     */
    async updateTemplatePageComponents(model) {
      const data = await this.processRequest("/TemplatePages/Components", "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to update computed image components on other pages
     *  @param {Object} model. Model with components
     *  @param {string} pageId. Page Id
     *  @param {string} templateId. Template Id
     *  @return {Object | null} Returns status object if success or null
     */
    async updateTemplateComponents(model, pageId, templateId) {
      const data = await this.processRequest(`/TemplatePages/UpdateComponents/${pageId}/${templateId}`, "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to copy current template page
     * @param {string} pageId. Selected Page ID
     * @param {string} templateId. Current Template ID
     * @return {Object | null} Returns status object if success or null
     */
    async copyTemplatePage(pageId, templateId) {
      const data = await this.processRequest(`/TemplatePages/Copy/${pageId}/Template/${templateId}`, 'post');
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
  }
};
