import constants from "@/store/admin/constants/admin";

export default {
  [constants.setInfoSearchPatients](context, payload) {
    context.commit(constants.setInfoSearchPatients, payload);
  },
  [constants.setInfoSearchPatientsSorted](context, payload) {
    context.commit(constants.setInfoSearchPatientsSorted, payload);
  },
  [constants.setInfoSearchPatientsSortOptions](context, payload) {
    context.commit(constants.setInfoSearchPatientsSortOptions, payload);
  },
  [constants.setPages](context, payload) {
    context.commit(constants.setPages, payload);
  },
  [constants.selectPage](context, payload) {
    context.commit(constants.selectPage, payload);
  },
  [constants.setSites](context, payload) {
    context.commit(constants.setSites, payload);
  },
  [constants.setSite](context, payload) {
    context.commit(constants.setSite, payload);
  },
  [constants.setTemplates](context, payload) {
    context.commit(constants.setTemplates, payload);
  },
  [constants.setTemplate](context, payload) {
    context.commit(constants.setTemplate, payload);
  },
  [constants.selectTemplate](context, payload) {
    context.commit(constants.selectTemplate, payload);
  },
  [constants.toggleUploadImagePopup](context, payload) {
    context.commit(constants.toggleUploadImagePopup, payload);
  },
  [constants.setEditorFiles](context, payload) {
    context.commit(constants.setEditorFiles, payload);
  },
  [constants.selectEditorFile](context, payload) {
    context.commit(constants.selectEditorFile, payload);
  },
  [constants.openCloseSuccessPopup](context, payload) {
    context.commit(constants.openCloseSuccessPopup, payload);
  },
  [constants.setSiteEvacuationMessages](context, payload) {
    context.commit(constants.setSiteEvacuationMessages, payload);
  },
  [constants.setActiveVisitorsIds](context, payload) {
    context.commit(constants.setActiveVisitorsIds, payload);
  },
  [constants.setEvacuationMessageText](context, payload) {
    context.commit(constants.setEvacuationMessageText, payload);
  },
  [constants.openCloseCsvUploadEmployeesPopup](context, payload) {
    context.commit(constants.openCloseCsvUploadEmployeesPopup, payload);
  },
  [constants.openCloseCsvDownloadEmployeesPopup](context, payload) {
    context.commit(constants.openCloseCsvDownloadEmployeesPopup, payload);
  },
  [constants.openCloseCsvUploadErrorsPopup](context, payload) {
    context.commit(constants.openCloseCsvUploadErrorsPopup, payload);
  },
  [constants.setCsvUploadErrors](context, payload) {
    context.commit(constants.setCsvUploadErrors, payload);
  },
  [constants.setBuildingVisitors](context, payload) {
    context.commit(constants.setBuildingVisitors, payload);
  },
  [constants.externalEmployees](context, payload) {
    context.commit(constants.externalEmployees, payload);
  },
  [constants.companySelectIonDialog](context, payload) {
    context.commit(constants.companySelectIonDialog, payload);
  },
  [constants.setAccessRoutes](context, payload) {
    context.commit(constants.setAccessRoutes, payload);
  },
  [constants.setNavigationObject](context, payload) {
    context.commit(constants.setNavigationObject, payload);
  },
};
