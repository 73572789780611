import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const clockSettings = {
  title: "Clock",
  [componentKeys.name]: "clockBase",

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.fontSize]: "16px",
    [componentKeys.color]: "white",
    [componentKeys.textAlign]: "right",
  },
  [componentKeys.properties]: {
    [componentKeys.dateFormat]: "",
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.fontSize]: "16px",
  }
};
