import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";
import constants from "@/store/admin/constants/admin";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  methods: {
    /**
     * * Send request to API to get all building visitors
     *  @param {String} buildingId. Id of current building
     */
    async getBuildingVisitors(buildingId) {
      const data = await this.processRequest(`/visitors/building/${buildingId}`);
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setBuildingVisitors, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
  }
};
