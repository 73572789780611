export default {
  methods: {
    /**
     * @return {boolean}
     */
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  }
};
