/* eslint-disable */
import Vue from 'vue';
import { directive as onClickOutside } from 'vue-on-click-outside';
import Vuelidate from 'vuelidate';
import Datetime from 'vue-datetime';
import CKEditor from "@ckeditor/ckeditor5-vue";

import store from '@/store/store';
import router from '@/router';
import App from '@/app/App';
import IsJsonString from '@/mixins/CheckJsonString';
import "@/libs/css/ckeditor.css";
import MiddlewareService from "@/MiddlewareService.js";
import { UrlLogger } from '@/helpers/Constants';

Vue.use(Vuelidate);
Vue.use(Datetime);
Vue.directive('on-click-outside', onClickOutside);

Vue.config.productionTip = true;

Vue.use(CKEditor);

new Vue({
  el: '#app',
  mixins: [MiddlewareService, IsJsonString],
  data() {
    return {
      clientData: {}
    };
  },
  router,
  store,
  methods: {
    sendReport(msg, src, error) {
      try {
        this.requestApi(`${UrlLogger}/Logs`, 'post', {
          message: JSON.stringify({ href: location.href, msg: msg.stack }),
          severity: 34 // FrontEnd | Critical
        }, false, false);
      } catch (ex) {
        console.log(ex);
      }
      console.log('error: ', msg);
    }
  },
  created() {
    if ((process.env.MODE === 'prod' || process.env.MODE === 'stage') && location.host.indexOf('localhost') === -1) {
      window.onerror = async(msg, src, linenum, colnum, error) => {
        this.sendReport(msg, src, error);
      };
      Vue.config.errorHandler = async(err, vm, info) => {
        this.sendReport(err, vm, info);
      };
      Vue.config.warnHandler = async(msg, vm, info) => {
        this.sendReport(msg, vm, info);
      };
    }
  },
  render: h => h(App),
});
