// Keys for navigation and Claims control over the application

export default {
  admin: "Admin",
  adminBuildings: "AdminBuildings",
  adminCompanies: "AdminCompanies",
  adminDepartments: "AdminDepartments",
  adminUsers: "UsersList",
  adminMessages: "adminMessages",

  companies: "Companies",
  companyAdmin: "CompanyAdmin",
  companyAdminBuildings: "CompanyAdminBuildings",
  companyAdminCompanies: "CompanyAdminCompanies",
  companyAdminDepartments: "CompanyAdminDepartments",
  companyAdminJobDepartments: "CompanyAdminJobDepartments",
  companyAdminJobTitles: "CompanyAdminJobTitles",
  companyAdminEmployees: "CompanyAdminEmployees",
  companyAdminDropin: "CompanyAdminDropin",
  companyAdminMessages: "CompanyAdminMessages",
  companyAdminDropinMessages: "companyAdminDropinMessages",
  companyAdminVisitorMessages: "companyAdminVisitorMessages",
  companyAdminEvacuationMessages: "companyAdminEvacuationMessages",
  companyAdminEmployeeMessages: "companyAdminEmployeeMessages",
  companyAdminSettings: "CompanyAdminSettings",
  kiosks: "Kiosks",
  logger: "Logger",

  jobDepartments: "JobDepartments",
  jobTitles: "JobTitles",

  dropIns: "DropIns",
  dropInUsers: "DropInUsers",
  dropInNotifications: "DropInNotifications",

  sites: "Sites",
  sitePages: "SitesPages",
  siteSettings: "SiteSettings",
  statistics: "Statistics",
  schedule: "Schedule",
  schedules: "Schedules",
  settings: "Settings",
  settingsMessages: "SettingsMessages",
  settingsSites: "SettingsSites",
  loginPictureSettings: "LoginPictureSettings",
  sitesNamedContent: "SitesNamedContent",
  langDictionary: "LangDictionary",

  versions: "Versions",
  entur: "Entur",
  enturLineEdit: "EnturLineEdit",
  easyPark: "EasyPark",
  taxi: "Taxi",

  templates: "Templates",
  templatePages: "TemplatesPages",

  loginLogs: "LoginLogs",
  userActivities: "UserActivities",
  users: "Users",
  employees: "Employees",
  userGroups: "UserGroups",
  userRoles: "Roles",
  notifications: "Notifications",
  employeeNotifications: "EmployeeNotifications",

  meetingServices: "MeetingServices",
  meetingRooms: "MeetingRooms",
  meetings: "Meetings",

  queue: "QueueSystem",
  queueLines: "QueueLines",

  visitors: "Visitors",
  visitorNotifications: "VisitorNotifications",
  visitorsCustom: "VisitorsCustom",
  visitorsCustomHeaders: "VisitorListHeaders",

  messageSettings: "MessageSettings",

  evacuationMessages: "EvacuationMessages",
  preregistrationMessages: "PreregistrationMessages",
  visitexpressMessages: "VisitexpressMessages",

  infoSearch: "InfoSearch",
  infoSearchSites: "InfoSearchSites",
  infoSearchAreas: "InfoSearchAreas",
  infoSearchPatients: "InfoSearchPatients",
  infoSearchCategories: "InfoSearchCategories",
  infoSearchStatistics: "InfoSearchStatistics",

  preRegisterMeetings: "PreRegisterMeetings",
  preRegisterMeetingEdit: "PreRegisterMeetingEdit",
  preRegisterMeetingStatus: "PreRegisterMeetingStatus",

  redirect: "Redirect",

  WebConstructor: 'WebConstructor',
  MeetingRoom: 'MeetingRoom',
  ProconEvent: 'ProconEvent',
  VisitExpress: 'VisitExpress',
  MultiQueueMenu: 'MultiQueueMenu',
  MultiQueueSetting: 'MultiQueueSetting',
  MultiQueue: 'MultiQueue',
  DesksTable: 'DesksTable',

  azureAd: 'azureAd',
  visitorPersistance: 'visitorPersistance',
  visitorsCustomFields: 'visitorsCustomFields',
  companyAgreement: 'companyAgreement',
  smsService: 'smsService',
  emailService: 'emailService',
  meetingDescription: 'meetingDescription',
  customLinks: 'customLinks',
  logo: 'logo',
  kioskAdmin: 'kioskAdmin',
  easyParkSettings: 'easyParkSettings',
  telenor: 'telenor',
};
