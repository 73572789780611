import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const responseMessageSettings = {
  title: "Response Message",
  [componentKeys.name]: "responseMessageBase",

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.fontSize]: "16px",
    [componentKeys.color]: "red",
    [componentKeys.textAlign]: "left",
  },
  [componentKeys.properties]: {
    [componentKeys.text]: ""
  }
};
