import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

const selectPersonConfirmDialogCancelBtn = {
  en: "Cancel",
  no: "Avbryt",
  sv: "Annullera",
  uk: "Відмінити",
};

const selectPersonConfirmDialogConfirmBtn = {
  en: "Confirm",
  no: "Bekreft",
  sv: "Bekräfta",
  uk: "Підтвердити",
};

const selectPersonConfirmDialog = {
  en: 'Is this the correct employee?',
  no: 'Er dette riktig ansatt?',
  sv: 'Är detta rätt anställd?',
  uk: 'Це правильний працівник?',
};

const selectPersonConfirmDialogBody = {
  en: "Is it {Name} you are going to visit?",
  no: "Er det {Name} du skal besøke?",
  sv: "Är det {Name} du ska besöka?",
  uk: "Ви хочете відвідати {Name}?",
};

export const defaultTranslations = {
  selectPersonConfirmDialogConfirmBtn,
  selectPersonConfirmDialogCancelBtn,
  selectPersonConfirmDialog,
  selectPersonConfirmDialogBody
};

export const searchTypes = {
  default: 0,
  employee: 1,
  visitorPhones: 2
};

export const searchTypesOptions = {
  [searchTypes.default]: "Search in preloaded employee list",
  [searchTypes.employee]: "Search Employee in Procon API",
  [searchTypes.visitorPhones]: "Search Visitor in PhoneAPI"
};

export const employeeListSettings = {
  title: 'Person Selector',
  [componentKeys.name]: 'employeesListBase',

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.bgColor]: "transparent",
    [componentKeys.color]: "black",
    [componentKeys.borderColor]: "grey",
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.fontSize]: "16px",
    [componentKeys.borderRadius]: "0",
  },
  [componentKeys.properties]: {
    nameTemplate: "{firstName} {lastName}",
    shouldShowEmplooyeeSelectConfirmDialog: false,
    [componentKeys.selectPersonConfirmDialog]: [],
    [componentKeys.selectPersonConfirmDialogBody]: [],
    [componentKeys.selectPersonConfirmDialogConfirmBtn]: [],
    [componentKeys.selectPersonConfirmDialogCancelBtn]: [],
    showOneEmplooyeeWhenSymbolFound: 0,
    searchType: searchTypes.default,
    hideIconOnItem: false,
    includeShadows: false,
    [componentKeys.selectedItemFontColor]: "#FFFFFF",
    [componentKeys.selectedItemBgColor]: "#198f35",
  }
};
