import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";
import adminConstants from "@/store/admin/constants/admin";
import WCConstants from "@/store/wc/constants/wc";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  methods: {
    /**
     * * Send request to API to create new site page
     *  @param {Object} model. Page model with properties
     *  @return {Object | null} Returns status object if success or null
     */
    async createSitePage(model) {
      const data = await this.processRequest("/SitePages", "post", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to update current site page
     *  @param {Object} model. Page model with properties
     *  @return {Object | null} Returns status object if success or null
     */
    async updateSitePage(model) {
      const data = await this.processRequest("/SitePages", "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to update computed image components on other pages
     *  @param {Object} model. Model with components
     *  @param {string} pageId. Page Id
     *  @param {string} siteId. Site Id
     *  @return {Object | null} Returns status object if success or null
     */
    async updateSiteComponents(model, pageId, siteId) {
      const data = await this.processRequest(`/SitePages/UpdateComponents/${pageId}/${siteId}`, "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to update current site page components
     *  @param {Object} model. Page model with properties
     *  @return {Object | null} Returns status object if success or null
     */
    async updateSitePageComponents(model) {
      const data = await this.processRequest("/SitePages/Components", "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },

    /**
     * * Send request to API to update properties on all pages in current site
     *  @param {Object} model. Page model with properties
     *  @return {Object | null} Returns status object if success or null
     */
    async updatePropertiesForAllPages(model) {
      const data = await this.processRequest("/SitePages/UpdateProperties", "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },

    /**
     * * Send request to API to get current page details by Id
     *  @param {String} pageId. Page ID
     *  @return {Object | null} Returns site page object if success or null
     */
    async getSitePageDetails(pageId, module) {
      let url = `/SitePages/${pageId}`;
      if (module) url += `?module=${module}`;
      const data = await this.processRequest(url);
      if (data?.body?.success) {
        const activePage = data.body.message;
        if (activePage.site.width) {
          activePage.site.siteHeight = activePage.site.height;
          activePage.site.siteWidth = activePage.site.width;
          for (const pc of activePage.pageComponents) {
            pc.pageComponentContents = pc.componentContents;
            pc.properties = JSON.parse(pc.properties);
            pc.styles = JSON.parse(pc.styles);
          }
        }
        await this.$store.dispatch(WCConstants.setActivePage, activePage);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
    /**
     * * Send request to API to remove site page
     *  @param {String} pageId. Page ID
     *  @return {Object | null} Returns status object if success or null
     */
    async removeSitePage(pageId, module) {
      let url = `/SitePages/${pageId}`;
      if (module) url += `?module=${module}`;
      const data = await this.processRequest(url, "delete");
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to get all page for site
     *  @param {String} siteId. Site ID
     */
    async getSitePages(siteId, module) {
      let url = `/SitePages/${siteId}/Pages`;
      if (module) url += `?module=${module}`;
      const data = await this.processRequest(url);
      if (data.ok) {
        await this.$store.dispatch(adminConstants.setPages, data.body);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
    /**
     * * Send request to API to replace all site pages with template pages
     * @param {string} templateId. Selected Template ID
     * @param {string} siteId. Current Site ID
     * @return {Object | null} Returns status object if success or null
     */
    async replaceSitePages(templateId, siteId) {
      const data = await this.processRequest(`/SitePages/Replace/${templateId}/Site/${siteId}`, 'put');
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to append template pages to the site pages
     * @param {string} templateId. Selected Template ID
     * @param {string} siteId. Current Site ID
     * @return {Object | null} Returns status object if success or null
     */
    async appendTemplatePages(templateId, siteId) {
      const data = await this.processRequest(`/SitePages/Append/${templateId}/Site/${siteId}`, 'put');
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to copy current site page
     * @param {string | number} pageId. Selected Page ID
     * @param {string} siteId. Current Site ID
     * @return {Object | null} Returns status object if success or null
     */
    async copySitePage(pageId, siteId, module) {
      let url = `/SitePages/Copy/${pageId}/Site/${siteId}`;
      if (module) url += `?module=${module}`;
      const data = await this.processRequest(url, 'post');
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
  }
};
