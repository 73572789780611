import defaultPosition from "@/helpers/wc/positionSettings";

export const eventsCalendarSettings = {
  title: 'EventsCalendar',
  name: 'eventsCalendarBase',
  styles: defaultPosition,

  properties: {
    calendarUrl: '',
    pageSize: 5,
    showPageholders: true,
    hideCategories: 'procon',
    css: {
      events: {
        fontFamily: "Arial",
        fontSize: 14,
        color: 'black',
        backgroundColor: 'white',
        padding: '10px',
        paddingBottom: '15px',
        borderColor: 'rgb(57, 61, 121)',
        borderWidth: '2px'
      },
      details: {
        paddingTop: '0'
      },
      date: {
        fontFamily: "Arial",
        fontSize: '16px',
        padding: '5px',
        color: 'black',
        backgroundColor: 'white'
      },
      summary: {
        fontFamily: "Arial",
        fontSize: '20px',
        color: 'rgb(223 110 30)',
        padding: '5px'
      },
      navigation: {
        backgroundColor: 'white',
        padding: '5px',
        paddingTop: '10px',
        borderRadius: 0
      },
      button: {
        fontSize: '20px',
        color: 'black',
        backgroundColor: 'white',
        width: '35px',
        padding: '5px',
        borderColor: 'grey',
        borderWidth: '2px',
        borderRadius: '20px'
      },
      prevButton: {
        top: 0,
        left: '20px'
      },
      nextButton: {
        top: 0,
        right: '20px'
      },
      placeholder: {
        fontSize: 15,
        color: '#aeb7e2',
        padding: '0'
      }
    }
  },
  cssLegend: {
    events: {
      name: "Events",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      },
      paddingBottom: {
        type: "int",
        name: "Bottom padding",
        handler: val => val + 'px'
      },
      borderColor: {
        type: "color",
        name: "Border"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      }
    },
    details: {
      name: "Event details",
      paddingTop: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      }
    },
    date: {
      name: "Date caption",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      }
    },
    summary: {
      name: "Header",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      }
    },
    navigation: {
      name: "Page Navigation",
      backgroundColor: {
        type: "color",
        name: "Background"
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      },
      paddingTop: {
        type: "int",
        name: "Padding top",
        handler: val => val + 'px'
      },
      borderRadius: {
        type: "int",
        name: "Border Radius",
        handler: val => val + 'px'
      }
    },
    button: {
      name: "Page Button",
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      },
      width: {
        type: "int",
        name: "Width",
        handler: val => val + 'px'
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      },
      borderColor: {
        type: "color",
        name: "Border"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      },
      borderRadius: {
        type: "int",
        name: "Border Radius",
        handler: val => val + 'px'
      }
    },
    prevButton: {
      name: "'Prev' button",
      top: {
        type: "int",
        name: "top",
        handler: val => val + 'px'
      },
      left: {
        type: "int",
        name: "left",
        handler: val => val + 'px'
      }
    },
    nextButton: {
      name: "'Next' button",
      top: {
        type: "int",
        name: "top",
        handler: val => val + 'px'
      },
      right: {
        type: "int",
        name: "right",
        handler: val => val + 'px'
      }
    },
    placeholder: {
      name: "Page Placeholder",
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      }
    }
  }
};
