import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const checkboxTypesRegister = {
  /* blockAllButtonsClick: {
    title: "Block all buttons click",
    value: 1
  }, */
  blockSpecificButton: {
    title: "Block specific button",
    value: 2
  },
  acceptAgreement: {
    title: "Accept agreement",
    value: 3,
  },
};

export const checkboxStyleTypes = {
  default: {
    title: "Default",
    value: 1
  },
  cross: {
    title: "Сross",
    value: 2
  },
};

export const checkBox = {
  title: 'Check Box',
  [componentKeys.name]: 'checkBoxBase',

  [componentKeys.styles]: {
    ...defaultPosition,
  },

  [componentKeys.properties]: {
    [componentKeys.type]: checkboxTypesRegister.blockSpecificButton.value,
    [componentKeys.checkBoxValue]: false,
    [componentKeys.checkBoxStyleType]: checkboxStyleTypes.default.value,
    [componentKeys.buttonId]: '',
  }
};
