import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const queueOperatedVisitor = {
  title: 'Queue Operated Visitor',
  [componentKeys.name]: 'queueOperatedVisitorBase',

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.bgColor]: 'grey',
    [componentKeys.color]: "#fff",
    [componentKeys.borderColor]: "grey",
    [componentKeys.borderWidth]: "1px",
    [componentKeys.borderStyle]: "solid",
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.fontSize]: "16px",
  },
  [componentKeys.properties]: {
    [componentKeys.queueLineId]: "",
  }
};
