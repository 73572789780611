export default {
  none: {
    name: "none",
    value: 0
  },
  header: {
    name: "header",
    value: 1,
  },
  body: {
    name: "body",
    value: 2,
  },
  footer: {
    name: "footer",
    value: 3,
  },
};
