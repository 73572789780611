import MiddlewareService from "@/MiddlewareService.js";
import { UrlLangService } from "@/helpers/Constants";

export const mapTranslationKeys = (entries) => {
  const dict = {};
  entries.map(e => {
    dict[e.key] = e.text;
  });
  return dict;
};

export default {
  mixins: [MiddlewareService],
  computed: {
    langId() {
      return this.$store.state.token?.language;
    },
  },
  watch: {
    langId: {
      handler() {
        if (this.accessKey && this.langId) {
          this.loadTranslations(this.langId, this.accessKey);
        }
      },
      immediate: true
    }
  },
  methods: {
    // Load translations and cache them
    loadTranslations(langType, page) {
      if (this.$store.state.loadedPages.has(page)) {
        console.log(`Page ${page} is cached`);
        return;
      }
      this.requestApi(`${UrlLangService}/Lang/Page?lang=${langType}&page=${page}`).then(pageTr => {
        if (pageTr) {
          const translations = mapTranslationKeys(pageTr.body);
          this.$store.commit('updateLangKeys', translations);
          this.$store.commit('setLoadedPages', page);
        }
      });
    },
    getPhrase(key) {
      return this.$store.getters.getPhrase(key);
    }
  }
};
