import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";
import constants from "@/store/admin/constants/admin";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  methods: {
    /**
     * * Send request to API to upload file
     *  @param {Blob} file. File
     *  @return {Object | null} Returns status object if success or null
     */
    async uploadEditorFile(file, asCommon, module) {
      const formData = new FormData();
      formData.append('File', file);
      let url = `/EditorFiles?asCommon=${asCommon}`;
      if (module) url += `&module=${module}`;
      const data = await this.processRequest(url, "post", formData, true, false, "multipart/form-data");
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },

    /**
     * * Send request to API to upload file
     */
    async getEditorFiles(module) {
      let url = `/EditorFiles/List`;
      if (module) url += `?module=${module}`;
      const data = await this.processRequest(url);
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setEditorFiles, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },

    /**
     * * Assosiate file with current company, making it private
     */
    async setFilePrivate(file, module) {
      let url = `/EditorFiles/SetPrivate?fileId=${file.id}`;
      if (module) url += `&module=${module}`;
      const data = await this.processRequest(url, "post");
      if (data?.body?.success) {
        file.isCommon = false;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },

    /**
     * * Remove company data form the file, making it common
     */
    async setFilePublic(file, module) {
      let url = `/EditorFiles/SetPublic?fileId=${file.id}`;
      if (module) url += `&module=${module}`;
      const data = await this.processRequest(url, "post");
      if (data?.body?.success) {
        file.isCommon = true;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },

    /**
     * * Delete file
     */
    async delete(file, module) {
      let url = `/EditorFiles?fileId=${file.id}`;
      if (module) url += `&module=${module}`;
      const data = await this.processRequest(url, "delete");
      if (data?.body?.success) {
        file.isCommon = true;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
  }
};
