import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const iframe = {
  title: 'IFrame',
  [componentKeys.name]: 'iFrameBase',

  [componentKeys.styles]: {
    ...defaultPosition,
  },

  [componentKeys.properties]: {
    [componentKeys.siteUrl]: null,
  }
};
