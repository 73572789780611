import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const inputIdsRegister = {
  name: {
    title: 'Visitor Name',
    value: "visitorName"
  },
  phone: {
    title: 'Visitor Phone',
    value: "visitorPhone"
  },
  email: {
    title: 'Visitor Email',
    value: "visitorEmail"
  },
  companyName: {
    title: 'Visitor Company Name',
    value: "visitorCompanyName"
  },
  searchEmployee: {
    title: 'Search For Person',
    value: "searchEmployee"
  },
  searchPatients: {
    title: 'Search For Patients',
    value: 'searchPatients'
  },
  queueInitials: {
    title: 'Queue Initials',
    value: 'queueInitials'
  },
  licensePlate: {
    title: 'License Plate',
    value: 'lecinsePlate'
  },
  parkingHours: {
    title: 'Parking Hours',
    value: 'parkingHours'
  },
  passengerName: {
    title: 'Passenger Name',
    value: 'passengerName'
  },
  passengerPhone: {
    title: 'Passenger Phone',
    value: 'passengerPhone'
  },
  orderTime: {
    title: 'Order Time',
    value: 'orderTime'
  },
  search: {
    title: 'Search',
    value: 'search'
  }
};

export const inputTypesRegister = {
  text: {
    title: 'Text Input',
    value: 'text'
  },
  number: {
    title: 'Number Input',
    value: 'number'
  },
  phoneNumber: {
    title: 'Phone Number Input',
    value: 'tel'
  },
  time: {
    title: 'Time Input',
    value: 'time'
  },
  hidden: {
    title: 'Hidden Input',
    value: 'hidden'
  }
};

export const input = {
  title: 'Input',
  [componentKeys.name]: 'inputBase',
  [componentKeys.placeholder]: [],

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.color]: "#000",
    [componentKeys.fontSize]: '12px',
    [componentKeys.fontWeight]: 400,
    [componentKeys.borderWidth]: '1px',
    [componentKeys.borderStyle]: 'solid',
    [componentKeys.borderColor]: "#000",
    [componentKeys.borderRadius]: '5px',
    [componentKeys.fontFamily]: "sans-serif",
  },

  [componentKeys.properties]: {
    [componentKeys.inputId]: '',
    [componentKeys.inputType]: inputTypesRegister.text.value,
    [componentKeys.needValidate]: false,
    [componentKeys.disableValidationOutline]: false,
    [componentKeys.isRequired]: false,
    [componentKeys.maxLength]: 20,
    [componentKeys.neededDefaultParkingHours]: false,
    [componentKeys.parkingHours]: 0
  }
};
