// Register all components for CMS

import components from "@/helpers/client/components";

const mainClient = () => import("@/components/client/main/main");
const HomeMeetings = () => import("@/components/client/meetingroom/home/main");
const HomeVisitors = () => import("@/components/client/visitors/home/main");
const VisitorsRegister = () => import("@/components/client/visitors/register/main");
const VisitorsSearch = () => import("@/components/client/visitors/search/main");
const VisitorsSms = () => import("@/components/client/visitors/send-sms/main");
const VisitorsFinish = () => import("@/components/client/visitors/finish/main");
const DepartmentSms = () => import("@/components/client/visitors/departmentSms/main");
const DropinMessage = () => import("@/components/client/visitors/dropinMessage/main");
const DropinRegistartion = () => import("@/components/client/visitors/dropinRegistration/main");
const HomeInfoSearch = () => import("@/components/client/infoSearch/home/main");
const CategoryInfoSearch = () => import("@/components/client/infoSearch/categoryDetail/main");
const AreaInfoSearch = () => import("@/components/client/infoSearch/areaDetail/main");
const FeedBackForm = () => import("@/components/client/feedback/main");
const LeftVisitor = () => import("@/components/client/leftVisitorPage/leftVisitorPage");
const LeftVisitors = () => import("@/components/client/leftVisitorPage/leftVisitors");
const QueueNumber = () => import("@/components/client/queue/queueNumber/main");
const QueueInitials = () => import("@/components/client/queue/queueInitials/main");
const QueueVisitorsRegistration = () => import("@/components/client/visitors/queueRegistration/main");

export const clientComponents = {
  [components.mainClient]: mainClient,
  [components.HomeMeetings]: HomeMeetings,
  [components.HomeVisitors]: HomeVisitors,
  [components.VisitorsRegister]: VisitorsRegister,
  [components.VisitorsSearch]: VisitorsSearch,
  [components.VisitorsSms]: VisitorsSms,
  [components.VisitorsFinish]: VisitorsFinish,
  [components.DepartmentSms]: DepartmentSms,
  [components.DropinMessage]: DropinMessage,
  [components.DropinRegistartion]: DropinRegistartion,
  [components.HomeInfoSearch]: HomeInfoSearch,
  [components.CategoryInfoSearch]: CategoryInfoSearch,
  [components.AreaInfoSearch]: AreaInfoSearch,
  [components.FeedBackForm]: FeedBackForm,
  [components.LeftVisitor]: LeftVisitor,
  [components.LeftVisitors]: LeftVisitors,
  [components.QueueNumber]: QueueNumber,
  [components.QueueInitials]: QueueInitials,
  [components.QueueVisitorsRegistration]: QueueVisitorsRegistration,
};
