// All Client components names

export default {
  mainClient: "mainClient",
  HomeMeetings: "HomeMeetings",
  HomeVisitors: "HomeVisitors",
  VisitorsRegister: "VisitorsRegister",
  VisitorsSearch: "VisitorsSearch",
  VisitorsSms: "VisitorsSms",
  VisitorsFinish: "VisitorsFinish",
  DepartmentSms: "DepartmentSms",
  DropinMessage: "DropinMessage",
  DropinRegistartion: "DropinRegistartion",
  HomeInfoSearch: "HomeInfoSearch",
  CategoryInfoSearch: "CategoryInfoSearch",
  AreaInfoSearch: "AreaInfoSearch",
  FeedBackForm: "FeedBackForm",
  LeftVisitor: "LeftVisitor",
  LeftVisitors: "LeftVisitors",
  QueueNumber: "QueueNumber",
  QueueInitials: "QueueInitials",
  QueueVisitorsRegistration: "QueueVisitorsRegistration",
};
