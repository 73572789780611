import defaultPosition from "@/helpers/wc/positionSettings";

export const graveyardSettings = {
  title: 'Graveyard',
  name: 'graveyardBase',

  styles: defaultPosition,

  properties: {
    cemeteryNo: "01",
    scale: 1,
    entrance: 1,
    css: {
      path: {
        strokeWidth: 4,
        stroke: 'burlywood'
      },
      text: {
        fontFamily: "Arial",
        fontSize: '40px',
        stroke: 'black',
        fill: 'black'
      },
      origin: {
        stroke: 'red'
      }
    }
  },
  cssLegend: {
    path: {
      name: "Path",
      strokeWidth: {
        type: "int",
        name: "Width"
      },
      stroke: {
        type: "color",
        name: "Color"
      },
    },
    text: {
      name: "Text",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      stroke: {
        type: "color",
        name: "Color 1"
      },
      fill: {
        type: "color",
        name: "Color 2"
      }
    },
    origin: {
      name: "Origin (dev only)",
      stroke: {
        type: "color",
        name: "Color"
      }
    }
  }
};
