import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const logoSettings = {
  title: 'Logo',
  [componentKeys.name]: 'logoBase',
  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.opacity]: "1",
  },
  [componentKeys.properties]: {
    [componentKeys.companyId]: '',
  }
};
