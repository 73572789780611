
export const labelTemplatesRegister = {
  visitorLabelWithCompany: {
    title: "Default Label With Visitor Company",
    value: 1
  },
  visitorLabelWithoutCompany: {
    title: "Default Label Without Visitor Company",
    value: 2
  },
  visitorLabelWithVisitorCompany: {
    title: "Label With Visitor Company",
    value: 4
  },
  queueLabelWithNumbers: {
    title: "Label With Queue Number",
    value: 8
  }
};
