import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const DepartmentUsersSortModeEnum = Object.freeze({
  DEACTIVATED: "DEACTIVATED",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME"
});

export const departmentUsersSettings = {
  title: "Department Users",
  [componentKeys.name]: "departmentUsersBase",

  [componentKeys.styles]: defaultPosition,

  [componentKeys.properties]: {
    [componentKeys.routeName]: "",
    [componentKeys.employeesCount]: 6,
    [componentKeys.jobDepartment]: "",
    [componentKeys.messageToEmployee]: {},
    [componentKeys.fetchFromDepartment]: false,
    [componentKeys.showName]: true,
    [componentKeys.showJobTitle]: false,
    [componentKeys.showPhone]: true,
    [componentKeys.breakLine]: false,
    [componentKeys.messageTagVisitorCompany]: false,
    [componentKeys.messageTagVisitorName]: false,
    [componentKeys.messageTagVisitorPhone]: false,
    [componentKeys.messageTagVisitorAttendants]: false,
    [componentKeys.sendSms]: false,
    [componentKeys.sendEmail]: false,
    [componentKeys.redirectToRegister]: false,
    departmentUsersSortMode: DepartmentUsersSortModeEnum.DEACTIVATED,
    enablePagination: false,
    css: {
      departmentUsers: {
        color: "#000000",
        fontFamily: "sans-serif",
        textAlign: "left",
        fontSize: "16px",
        userItemWidth: "25%",
        justifyContent: "center",
      },
      userItemInnerSpacing: {
        paddingTop: "0",
        paddingRight: "15px",
        paddingBottom: "0",
        paddingLeft: "15px",
      },
      userItemImageBorder: {
        style: "none",
        width: "0",
        color: "none",
        radius: "0",
      },
      pagination: {
        "--pagination-color": "#f83d3d"
      }
    },
  },
  cssLegend: {
    departmentUsers: {
      name: "Root",
      color: {
        type: "color",
        name: "Color"
      },
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      textAlign: {
        type: "align",
        name: "Text Align"
      },
      fontSize: {
        type: "int",
        name: "Font Size (px)",
        handler: val => val + "px"
      },
      userItemWidth: {
        type: "int",
        name: "User Item Width (%)",
        handler: val => val + "%"
      },
      justifyContent: {
        name: "Item Justify",
        type: "flexJustify"
      }
    },
    userItemInnerSpacing: {
      name: "User Item Inner Spacing",
      paddingTop: {
        name: "Padding Top (px)",
        type: "int",
        handler: val => val + "px"
      },
      paddingLeft: {
        name: "Padding Left (px)",
        type: "int",
        handler: val => val + "px"
      },
      paddingBottom: {
        name: "Padding Bottom (px)",
        type: "int",
        handler: val => val + "px"
      },
      paddingRight: {
        name: "Padding Right (px)",
        type: "int",
        handler: val => val + "px"
      },
    },
    userItemImageBorder: {
      name: "User Item Image Border",
      style: {
        name: "Style",
        type: "borderStyle"
      },
      width: {
        name: "Width (px)",
        type: "int",
        handler: val => val + "px"
      },
      color: {
        name: "Color",
        type: "color",
      },
      radius: {
        name: "Radius (%)",
        type: "int",
        handler: val => val + "%"
      }
    },
    pagination: {
      name: "Pagination",
      "--pagination-color": {
        name: "Color",
        type: "color"
      }
    }
  }
};
