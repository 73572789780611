import constants from "@/store/admin/constants/admin";
import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  methods: {
    /**
     * * Send request to API to notify all visitors sms/email
     *  @param {Object} evacuationModel. Model
     */
    async sendEvacuationMessageToActiveVisitors(evacuationModel) {
      const sendSmsToVisitorsResponses = await this.processRequest('/SendNotifications/Evacuation/', 'post', evacuationModel);
      if (sendSmsToVisitorsResponses?.body?.success) {
        this.$store.commit(constants.openCloseSuccessPopup, true);
        return true;
      } else {
        this.$awn.alert(this.getErrorResponse(sendSmsToVisitorsResponses));
        return false;
      }
    },
    /**
     * * Send request to API to notify all visitors in building by sms
     *  @param {Object} evacuationModel. Model
     */
    async sendEvacuationMessageToBuildingVisitors(evacuationModel) {
      const sendSmsToVisitorsResponses = await this.processRequest('/SendNotifications/Evacuation/Sms', 'post', evacuationModel);
      if (sendSmsToVisitorsResponses?.body?.success) {
        console.log(sendSmsToVisitorsResponses.body.message);
        this.$store.commit(constants.openCloseSuccessPopup, true);
        return true;
      } else {
        this.$awn.alert(this.getErrorResponse(sendSmsToVisitorsResponses));
        return false;
      }
    },
  },
};
