<template>
  <div class="wrapper">
    <router-view v-if="showRoutes"></router-view>
    <spinner v-if="$store.state.loading"/>
  </div>
</template>

<script>
import MiddlewareService from "@/MiddlewareService";
// import { ClientService } from "@/helpers/Constants";
import addRoutesDynamic from '@/mixins/client/wc/addRoutesDynamic';
import requests from "@/mixins/admin/requests/requests";
import Vue from "vue";
import VueAWN from "vue-awesome-notifications";
import getType from "@/mixins/language/getLangType";
import clientSocket from '@/mixins/socket/socketClient';
import dictionary from "@/mixins/language/dictionary";
import less from 'less';

const spinner = () => import('@/components/cms/common/spinner');

export default {
  name: "app",
  mixins: [MiddlewareService, addRoutesDynamic, requests, getType, clientSocket, dictionary],
  data() {
    return {
      accessKey: 'AdminLayout',
      // responsible for showing routes in all app
      showRoutes: false,
    };
  },
  computed: {
    lg() {
      return this.getType();
    },
    path() {
      return this.$route?.path;
    }
  },
  components: {
    spinner
  },
  // watch: {
  //  path(val) {
  //    if (val.lastIndexOf('/') === 0) {
  //      this.load();
  //    }
  //  }
  // },
  async beforeMount() {
    await this.load();
  },
  methods: {
    async load() {
      // set default lang in token to prevent fetching with undefined
      this.$store.commit('tokenLang', localStorage.lang ?? 'no');
      if (this?.lg?.length) {
        Vue.use(VueAWN, {
          duration: 2000,
          position: 'top-right',
          labels: {
            success: this.getPhrase('success'),
            warning: this.getPhrase('warning'),
            alert: this.getPhrase('alert'),
          }
        });
      }
      const dynamic = this.$route?.query?.dynamic;
      if (dynamic) {
        // try {
        //  const res = await this.requestApi(ClientService, 'get', {}, false, false);
        //  localStorage.token = res.body;
        // } catch (ex) {
        //  localStorage.token = '';
        // }
        const siteId = this.getParameterFromQuery();
        await this.getClientSiteDetails(siteId, dynamic);
        if (this.$store.state.site.style) {
          less.render(this.$store.state.site.style).then(res => {
            document.head.insertAdjacentHTML('beforeend', `<style>${res.css}</style>`);
          });
        }
        this.initClientSocket(siteId);
        // try to push new routes to the all routes
        this.addRoutesDynamic(this.$store.state.site?.sitePages);
        // when routes are loaded -> show route
        this.showRoutes = true;
      } else {
        // if not dynamic routes allow to open immediately
        this.showRoutes = true;
      }
      if (!localStorage.lang) {
        localStorage.lang = 'no';
      }
    }
  }
};
</script>
<style lang="scss">
  @import '~vue-awesome-notifications/dist/styles/style.scss';
  @import '~@/styles/general/spinner';

  html {
    user-select: none;
  }
</style>
