import checkJson from '@/mixins/CheckJsonString';
import socket from '@/mixins/socket/socket';
import { BASE_URL, SOCKET, socketTypes } from '@/helpers/Constants';

export default {
  mixins: [socket, checkJson],
  data() {
    return {
      socketUrl: '',
      pingTimeOut: null,
      pingTimer: 10000,
      siteId: null
    };
  },
  methods: {
    initClientSocket(siteId) {
      this.siteId = siteId;
      this.socketUrl = `${SOCKET}${BASE_URL.split('//')[1].split('/')[0]}/ws?siteId=${this.siteId}`;

      this.resetSocketTimer();

      this.connect(this.socketUrl);
    },
    resetSocketTimer() {
      this.pingTimeOut = setTimeout(async() => {
        if (this.socket) {
          this.sendMessage(JSON.stringify({
            id: `${this.siteId}`,
            toSocketId: 'main',
            message: socketTypes.ping
          }));
        } else {
          this.connect(this.socketUrl);
        }
        this.resetSocketTimer();
      }, this.pingTimer);
    },
    sendMessage(message) {
      if (!this.isOpen(this.socket)) {
        if (this.socket.readyState === this.socket.CLOSED) {
          if (navigator.onLine) {
            this.connect(this.socketUrl);
          }
        } else {
          if (!this.closed) {
            this.closeSocket();
          }
        }
      } else {
        this.socketSend(message);
      }
    },
    onOpenSocket(event) {
      this.closed = false;
      this.sendMessage(JSON.stringify({
        id: `${this.siteId}`,
        toSocketId: 'main',
        message: socketTypes.ping
      }));
    },
    clearSocketTimeout() {
      clearTimeout(this.pingTimeOut);
    },
    onSocketMessage(event) {
      const response = JSON.parse(event.data);
      const isJson = this.IsJsonString(response.message);
      if (isJson) {
        const respMessage = JSON.parse(response.message);
        if (respMessage.toSocketId === this.siteId || respMessage.toSocketId === socketTypes.all) {
          if (respMessage.message === socketTypes.reload) {
            this.sendMessage(JSON.stringify({
              id: `${this.siteId}`,
              toSocketId: 'main',
              message: socketTypes.reloadSuccess
            }));
            location.reload();
          }
        }
      }
    },
  }
};
