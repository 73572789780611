import componentKeys from "@/helpers/wc/componentKeys";

export default {
  [componentKeys.w]: '100px',
  [componentKeys.h]: '100px',
  [componentKeys.x]: '50px',
  [componentKeys.y]: '50px',
  [componentKeys.z]: 0,
  [componentKeys.ableToShift]: false,
  [componentKeys.shiftedY]: 0,
  [componentKeys.transform]: 'translateY(0)'
};
