import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const infoSearchSubCategoriesButtonsSettings = {
  title: "InfoSearch Sub Categories",
  [componentKeys.name]: "infoSearchSubCategoriesButtonsBase",

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.color]: "#000000",
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.textAlign]: "left",
    [componentKeys.marginTop]: "0",
    [componentKeys.marginRight]: "0",
    [componentKeys.marginBottom]: "5",
    [componentKeys.marginLeft]: "0",

    [componentKeys.paddingTop]: "0",
    [componentKeys.paddingRight]: "15",
    [componentKeys.paddingBottom]: "0",
    [componentKeys.paddingLeft]: "15",

    [componentKeys.flexDirection]: "column",
    [componentKeys.justifyContent]: "center",
    [componentKeys.alignItems]: "center",

    [componentKeys.fontSize]: '20px',
    [componentKeys.fontWeight]: 400,
    [componentKeys.borderWidth]: '1px',
    [componentKeys.borderStyle]: 'solid',
    [componentKeys.borderColor]: "#000000",
    [componentKeys.bgColor]: 'unset',
    [componentKeys.borderRadius]: '5px',
    [componentKeys.itemWidth]: '45',
    [componentKeys.itemHeight]: '45',
  },
  [componentKeys.properties]: {
    [componentKeys.routeName]: "",
    [componentKeys.infoSearchCategory]: {},
    [componentKeys.infoSearchCategories]: [],
    [componentKeys.fakeCategoriesCount]: 6,
  }
};
