import constants from '@/store/wc/constants/wc';

export default {
  [constants.setSiteResolution](context, payload) {
    context.commit(constants.setSiteResolution, payload);
  },
  [constants.setPages](context, payload) {
    context.commit(constants.setPages, payload);
  },
  [constants.setActivePage](context, payload) {
    const { site } = payload;
    context.commit(constants.setSiteResolution, {
      width: site?.siteWidth ?? 0,
      height: site?.siteHeight ?? 0
    });
    context.commit(constants.setPage, payload);
  },
  [constants.setTemplateActivePage](context, payload) {
    const { template } = payload;
    context.commit(constants.setSiteResolution, {
      width: template?.width ?? 0,
      height: template?.height ?? 0
    });
    context.commit(constants.setPage, payload);
  },
  [constants.setCoefficient](context, payload) {
    context.commit(constants.setCoefficient, payload);
  },
  [constants.deactivatePageComponents](context, payload) {
    context.commit(constants.deactivatePageComponents, payload);
  },
  [constants.updatePageComponents](context, payload) {
    context.commit(constants.updatePageComponents, payload);
  },
  [constants.activateComponent](context, payload) {
    context.commit(constants.activateComponent, payload);
  },
  [constants.setEmployeeSearchString](context, payload) {
    context.commit(constants.setEmployeeSearchString, payload);
  },
  [constants.setEmployee](context, payload) {
    context.commit(constants.setEmployee, payload);
  },
  [constants.setEmployees](context, payload) {
    context.commit(constants.setEmployees, payload);
  },
  [constants.setAllEmployees](context, payload) {
    context.commit(constants.setAllEmployees, payload);
  },
  [constants.setDefaultEmployees](context, payload) {
    context.commit(constants.setDefaultEmployees, payload);
  },
  [constants.setVisitor](context, payload) {
    context.commit(constants.setVisitor, payload);
  },
  [constants.removeVisitor](context, payload) {
    context.commit(constants.removeVisitor, payload);
  },
  [constants.setLocalPageSize](context, payload) {
    context.commit(constants.setLocalPageSize, payload);
  },
  [constants.saveDropinSettings](context, payload) {
    context.commit(constants.saveDropinSettings, payload);
  },
  [constants.validateInput](context, payload) {
    context.commit(constants.validateInput, payload);
  },
  [constants.setCompanyQueueLines](context, payload) {
    context.commit(constants.setCompanyQueueLines, payload);
  },
  [constants.setQueueVisitors](context, payload) {
    context.commit(constants.setQueueVisitors, payload);
  },
  [constants.setJobDepartments](context, payload) {
    context.commit(constants.setJobDepartments, payload);
  },
  [constants.setJobDepartment](context, payload) {
    context.commit(constants.setJobDepartment, payload);
  },
  [constants.setDepartmentUsers](context, payload) {
    context.commit(constants.setDepartmentUsers, payload);
  },
  [constants.setFinishRegisterSettings](context, payload) {
    context.commit(constants.setFinishRegisterSettings, payload);
  },
  [constants.setElementsShifted](context, payload) {
    context.commit(constants.setElementsShifted, payload);
  },
  [constants.setInfoSearchCategories](context, payload) {
    context.commit(constants.setInfoSearchCategories, payload);
  },
  [constants.setInfoSearchCategory](context, payload) {
    context.commit(constants.setInfoSearchCategory, payload);
  },
  [constants.setInfoSearchSubCategory](context, payload) {
    context.commit(constants.setInfoSearchSubCategory, payload);
  },
  [constants.graveyard](context, payload) {
    context.commit(constants.graveyard, payload);
  },
  [constants.patient](context, payload) {
    context.commit(constants.patient, payload);
  },
  [constants.searchPage](context, payload) {
    context.commit(constants.searchPage, payload);
  },
  [constants.buttonState](context, payload) {
    context.commit(constants.buttonState, payload);
  }
};
