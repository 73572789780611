import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const video = {
  title: 'Video',
  [componentKeys.name]: 'videoBase',

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.opacity]: "1",
  },

  [componentKeys.properties]: {
    [componentKeys.inputId]: "",
    [componentKeys.routeName]: "",
    [componentKeys.url]: null,
    [componentKeys.isComputed]: false,
    [componentKeys.externalUrl]: null,
  }
};
