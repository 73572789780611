import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";
import constants from "@/store/admin/constants/admin";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  methods: {
    /**
     * * Send request to API to get list of templates
     */
    async getTemplatesList() {
      const data = await this.processRequest('/Templates/List');
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setTemplates, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
    /**
     * * Send request to API to get filtered list of templates
     * @param {number} width. Width of template
     * @param {number} height. Height of template
     * @param {number} type. Type of template
     */
    async getTemplatesFilteredList(width, height, type) {
      const data = await this.processRequest(`/Templates/FilteredList?width=${width}&height=${height}&type=${type}`);
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setTemplates, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
    /**
     * * Send request to API to get template details
     * @param {string} templateId. Id of current template
     */
    async getTemplateById(templateId = this.$route.params.templateId) {
      const data = await this.processRequest(`/Templates/${templateId}`);
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setTemplate, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
    /**
     * * Send request to API to create new template
     *  @param {Object} model. Template model with properties
     *  @return {Object | null} Returns template object if success or null
     */
    async createTemplate(model) {
      const data = await this.processRequest('/Templates', 'post', model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to create new template
     *  @param {Object} id. Template model with properties
     *  @return {Object | null} Returns template object if success or null
     */
    async copyTemplate(id) {
      const data = await this.processRequest(`/Templates/Copy/${id}`, 'post');
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to update current template
     *  @param {Object} model. Template model with properties
     *  @return {Object | null} Returns template object if success or null
     */
    async updateTemplate(model) {
      const data = await this.processRequest('/Templates', 'put', model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to delete template
     *  @param {String} templateId. Template model with properties
     *  @return {Object | null} Returns template object if success or null
     */
    async removeTemplate(templateId) {
      const data = await this.processRequest(`/Templates/${templateId}`, 'delete');
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    }
  }
};
