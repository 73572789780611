import Vue from "vue";
import VueResource from "vue-resource";
import Router from "vue-router";

import { BASE_URL } from "@/helpers/Constants";

Vue.use(Router);
Vue.use(VueResource);

export default {
  methods: {
    // general method for processing all requests through the application
    processRequest(url = "", method = "get", data = {}, showSpinner = true, customURL = false, contentType = "application/json") {
      if (showSpinner) {
        this.$store.commit("addRequest");
      }
      if (!customURL) {
        url = BASE_URL + url;
      }
      if (showSpinner) {
        this.$store.commit("setLoad", true);
      }
      Vue.http.interceptors.push((request, next) => {
        let authorization = this.$store.state.token.bearer;
        if (authorization) localStorage.token = sessionStorage.token = authorization;
        else authorization = sessionStorage.token || localStorage.token;
        if (!customURL) {
          request.headers.set("Authorization", authorization);
          request.headers.set("RefUrl", location.href);
          request.headers.set("RefPage", this.$store.state.token.siteId ? 'dynamic' : this.$route.name?.toString());
        }
        next();
      });
      return Vue.http[method](url, data, {
        headers: {
          "Content-Type": contentType,
          "Access-Control-Allow-Origin": location.origin
        }
      }).then(
        response => {
          if (showSpinner) this.$store.commit("removeRequest");
          if (this.$store.state.activeRequests === 0) {
            this.$store.commit("setLoad", false);
          }
          return response;
        },
        response => {
          if (showSpinner) this.$store.commit("removeRequest");
          if (this.$store.state.activeRequests === 0) {
            this.$store.commit("setLoad", false);
          }
          if (response?.body?.message === "Not Authenticated") {
            this.$router.push('/admin/login');
            // todo need to use toast message globally
            //  or find the way how to block other notifications when we receive "Not Authenticated" response
            this.$awn.warning("Your session has been expired. Please login again");
          }
          return response;
        }
      );
    },
    requestApi(url = "", method = "get", data = {}, showSpinner = true, showError = true, contentType = "application/json") {
      if (showSpinner) {
        if (typeof (showSpinner) === "function") {
          showSpinner(true);
        } else {
          this.$store.commit("addRequest");
          this.$store.commit("setLoad", true);
        }
      }
      Vue.http.interceptors.push((request, next) => {
        let authorization = this.$store.state.token.bearer;
        if (authorization) localStorage.token = sessionStorage.token = authorization;
        else authorization = sessionStorage.token || localStorage.token;
        request.headers.set("Authorization", authorization);
        request.headers.set("RefUrl", location.href);
        request.headers.set("RefPage", this.$store.state.token.siteId ? 'dynamic' : this.$route.name?.toString());
        next();
      });
      return Vue.http[method](url, data, {
        headers: {
          "Content-Type": contentType,
          "Access-Control-Allow-Origin": location.origin
        }
      }).then(
        response => {
          if (showSpinner) {
            if (typeof (showSpinner) === "function") {
              showSpinner(false);
            } else {
              this.$store.commit("removeRequest");
              if (this.$store.state.activeRequests === 0) {
                this.$store.commit("setLoad", false);
              }
            }
          }
          return response;
        },
        response => {
          if (showSpinner) {
            if (typeof (showSpinner) === "function") {
              showSpinner(false);
            } else {
              this.$store.commit("removeRequest");
              if (this.$store.state.activeRequests === 0) {
                this.$store.commit("setLoad", false);
              }
            }
          }
          if (response?.status === 401) {
            this.$router.push('/admin/login');
          }
          if (showError) {
            let path = url;
            const api = url.split('/api');
            if (api.length > 1) path = api.pop();
            else {
              const lambda = url.split('amazonaws.com/');
              if (lambda.length > 1) path = lambda.pop();
            }
            this.$awn.warning(`Can not ${method} on ${path}`);
          }
          return Promise.reject(response);
        }
      );
    }
  }
};
