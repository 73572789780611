import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from '@/helpers/wc/positionSettings';
import textAlignProperties from "./textAlignProperties";

export const openHoursSettings = {
  title: 'SVV Open Hours',
  [componentKeys.name]: 'svvOpenHours',
  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.fontSize]: '12px',
    [componentKeys.fontWeight]: 400,
    [componentKeys.color]: "#000",
    [componentKeys.textAlign]: textAlignProperties.center.value,
    [componentKeys.fontFamily]: "sans-serif"
  },
  [componentKeys.properties]: {
    [componentKeys.station]: '',
    [componentKeys.openHoursRefresh]: 5,
    [componentKeys.openHoursText]: [],
    [componentKeys.svvAddressText]: [],
  },
};
